import { GameEngineInterface, mapDispatchToProps, mapStateToProps } from './game-engine-interface.class';
import {SFSEvent, SFSObject, SFSRoom, SFSUser} from 'sfs2x-api';
import { connect } from 'react-redux';

class SharkGameEngineInterface extends GameEngineInterface {
    private bla = () => {
        console.log('test');
    };

    protected configureSFS() {
        const useEncryption = true;
        const {app} = this.props;

        // Create configuration object
        let hostName: string = app.urlParams['sfshost'].toLowerCase();
        const teacherName: string = app.urlParams['sfsteacher'];
        if (teacherName === 'Shark Table' && hostName === 'acbl') {
            hostName = 'sfs';
        }

        this.config = {
            host: hostName === 'local' ? 'local.onlinebridge.club' : hostName + '.emabridge.com',
            port: hostName === 'local' ? 8443 : 443,
            useSSL: useEncryption,
            zone: app.urlParams['service']
                  ? app.urlParams['service']
                  : app.urlParams['jwe']
                    ? 'OnlineBridgeClub'
                    : app.urlParams['sfsteacher'] === 'bigteamgame'
                      ? 'EmaBridge_TheBigTeamGame'
                      : 'EmaBridge_Teacher'
        };
    }

    protected onLoginError = ({ errorMessage, errorCode }: { errorMessage: string; errorCode: number }) => {
        console.log('Shark Bridge onLoginError : ',  errorMessage, errorCode);
        this.setState({ processingLogin: true });
    };

    protected onUserEnterRoom = ({ room, user }: { room: SFSRoom; user: SFSUser }) => {
        //this.props.showChatSendForm(room.getUserList().length > 1);
    };

    //IMPORTANT: if the user that left is ME, then move out of table to lobby, for now probably just a simple note or somethign.
    /**/
    protected onUserExitRoom = ({ room, user }: { room: SFSRoom; user: SFSUser }) => {
        // if (user.isItMe) {
        //     if (room.id !== this.sfs.lastJoinedRoom) return;
        //     //Close the table interface and back to lobby.
        //     this.props.resetGameExcept(['jitsi', 'metaData', 'gamePhase']);
        //     this.props.setGamePhase(GamePhase.END);
        //     //this.props.resetGamePartials(['tricks', 'winner', 'contract', 'stake', 'cards', 'auction','gamePhase','gameResults']);
        //     if (room.isGame) {
        //         /*
        //         this.setState({
        //             meetingState:
        //                 this.state.jitsiconf !== undefined || this.state.twitchconf !== undefined ? MeetingState.inLobby : MeetingState.NONE
        //         });
        //         //this.state.jitsibreakconf = undefined;
        //         this.refreshMeeting();
        //
        //          */
        //     }
        // } else {
        //     this.props.showChatSendForm(room.getUserList().length > 1);
        // }
    };

    //This is like the onRoomJoin, but the player was actually kibitzing an is now seated at the table
    protected onSpectatorToPlayerSwitch = ({ room, user, playerID }: { room: SFSRoom; user: SFSUser; playerID: number }) => {
        // if (!room.isGame || room !== this.sfs.lastJoinedRoom || !user.isItMe) {
        //     return;
        // }
        // const playerRID: number = this.sfs.mySelf.getPlayerId(room);
        // this.props.setMyBridgePosition(convertPlayerToBridgePosition(playerRID));
        // this.setState({
        //     myposition: convertPlayerToBridgePosition(playerRID)
        // });
        // this.sendRoomCommand(SFSVAR.CMD_INTERFACE_READY, new SFSObject());
    };

    protected onSpectatorToPlayerSwitchError = ({ errorMessage, errorCode }: { errorMessage: string; errorCode: number }) => {
        //console.log('Unable to become a player due to the following error: ' + errorMessage);
    };

    //Unseat the player, it turns in Kibitz. The important action here is to adjust hand visibility based on current rules for kibitzers
    protected onPlayerToSpectatorSwitch = ({ room, user }: { room: SFSRoom; user: SFSUser }) => {
        // if (!room.isGame || room !== this.sfs.lastJoinedRoom || user.isItMe) {
        //     return;
        // }
        // this.props.hideButtons(['undo', 'claim']);
        // this.props.setMyBridgePosition(convertIntToBridgePosition(this.state.currentPlayerOnServer));
        // this.setState({
        //     myposition: BridgePosition.south
        // });
        // this.sendRoomCommand(SFSVAR.CMD_INTERFACE_READY, new SFSObject());
    };

    protected onPlayerToSpectatorSwitchError = ({ errorMessage, errorCode }: { errorMessage: string; errorCode: number }) => {
        //console.log('Unable to become a spectator due to the following error: ' + errorMessage);
    };

    //This one can have data, regarding request to unblock a user, we will not deal with it now.
    protected onAdminMessage = ({ message, sender, data }: { message: any; sender: SFSUser; data: SFSObject }) => {
        // const apiCall: boolean = data ? data.containsKey('sendername') : false;
        // const isPop: boolean = data ? data.containsKey('popup') : false;
        // if (!isPop) {
        //     const senderName: string = apiCall ? data.getUtfString('sendername') : 'Shark';
        //     this.props.addChatEntry({ timestamp: moment.utc().format(), sender: senderName, message: message, isSystemMessage: !apiCall });
        //     return;
        // }
    };

    protected onModeratorMessage = ({ message, sender }: { message: string; sender: SFSUser }) => {
       return;
    };
    //On a special case where there is data sent back,
    protected onPublicMessage = ({ message, sender, data }: { message: string; sender: SFSUser; data: SFSObject }) => {
        // this.props.addChatEntry({
        //     timestamp: moment.utc().format(),
        //     sender: sender.containsVariable('pn')
        //             ? sender.getVariable('pn').value
        //             : sender.containsVariable('nn')
        //               ? sender.getVariable('nn').value
        //               : sender.name,
        //     message: message
        // });
        // if (message === 'fullscreen') {
        //     this.props.setJitsiFullSize(true);
        // } else if (message === 'normalscreen') {
        //     this.props.setJitsiFullSize(false);
        // }
    };

    protected onPrivateMessage = ({ message, sender, data }: { message: string; sender: SFSUser; data: SFSObject }) => {
        // this.props.addChatEntry({
        //     timestamp: moment.utc().format(),
        //     sender: sender.containsVariable('pn')
        //             ? sender.getVariable('pn').value
        //             : sender.containsVariable('nn')
        //               ? sender.getVariable('nn').value
        //               : sender.name,
        //     message: message
        // });
        // if (this.consolOut) {
        //     console.log('Received message: ' + message);
        // }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SharkGameEngineInterface);
