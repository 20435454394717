import {BridgePosition, CardPosition, SeatData, SeatPosition} from '../app/types';
import {CardUpdateProps} from '../slices/cardsSlice';
import {SFSObject} from 'sfs2x-api';
import {SFS_HAND_PROPS, SFS_HAND_STATE} from './sfsVar';
import {TableInfo} from '../slices/tableSlice';
import {fixColor, getCardState, sizeToCardMultiplier, strToCardId} from './game-engine-helper';


export const convert_SFS_HAND_PROPS = (handEnum: SFS_HAND_PROPS | undefined): {b: BridgePosition, s: SeatPosition, v: number} => {
    switch (handEnum) {
        case SFS_HAND_PROPS.north:
            return {b: BridgePosition.north, s: SeatPosition.top, v: 2}; // v is visibility modifier

        case SFS_HAND_PROPS.east:
            return {b: BridgePosition.east, s: SeatPosition.right, v: 4};

        case SFS_HAND_PROPS.south:
            return {b: BridgePosition.south, s: SeatPosition.bottom, v : 8};

        case SFS_HAND_PROPS.west:
            return {b: BridgePosition.west, s: SeatPosition.left, v: 16};

        default:
            return {b: BridgePosition.north, s: SeatPosition.top, v: 2};
    }
};

export const tableInfoFromSFSObject = (infoObj: SFSObject, obj: string) : TableInfo => {
    if (!infoObj.containsKey(obj+'hidden') || infoObj.getBool(obj+'hidden')) { return undefined; }
    return {
        label: infoObj.getUtfString(obj+'label'),
        value: infoObj.getUtfString(obj+'info'),
        hidden: infoObj.getBool(obj+'hidden'),
    }
    return undefined;
}

export const cardSetFromString = (hand: string[], sfsHandState: SFS_HAND_STATE, stateStr: string) : CardUpdateProps[] => {
    const cardUpdates: CardUpdateProps[] = hand.map((c) => ({
        bridgePosition: sfsHandState.bridgePosition,
        cardPosition: CardPosition.HAND,
        id: strToCardId(c),
        raised: getCardState(stateStr, c, '$'),
        highlighted: getCardState(stateStr, c, '!'),
        visible: getCardState(stateStr, c, '^') ? !sfsHandState.visible : undefined,
    }));
    return cardUpdates;
}

export const seatDataFromSFSObject = (sfsHandState: SFS_HAND_STATE, sizeModifier: number, dynamicTray: boolean, hand: string[], isdummy: boolean, isvisible: boolean, handSize: string) : { seatPosition: SeatPosition } & Partial<SeatData>  => {
    const result : { seatPosition: SeatPosition } & Partial<SeatData> = {
        background: sfsHandState.state.containsKey('highlight') ? fixColor(sfsHandState.state.getUtfString('highlight')) : undefined,
        cardWidthMultiplier: sizeToCardMultiplier(sfsHandState.state.getUtfString('size'), sizeModifier),
        dynamicCardColumnsWidth: dynamicTray,
        dummyCards: hand.length > 0 && hand[0].length > 0 ? 0 : sfsHandState.state.getInt('ncards'),
        isVertical: isdummy,
        isVisible: sfsHandState.visible || isvisible,
        labelPrimary: sfsHandState.state.getUtfString('firstl'),
        labelSecondary: sfsHandState.state.getUtfString('secondl'),
        labelTertiary: sfsHandState.state.getUtfString('thirdl'),
        seatPosition: sfsHandState.seatPosition,
        size: isdummy ? 'd' : !dynamicTray ? 'm' : handSize as 's' | 'm' | 'l' | 'd'
    }
    return result;
}
export const getSFSHandState = (hand : SFS_HAND_PROPS, state: SFSObject, visibility: number,  visibleWalkthru: boolean) : SFS_HAND_STATE | undefined  =>{
    if (state.containsKey(hand)) {
        if (hand === SFS_HAND_PROPS.center) {
            return   {
                state: state.getSFSObject(hand),
                visible: true,
                seatPosition: SeatPosition.top,
                bridgePosition: BridgePosition.north,
                visibleWalkthru: false,
            }
        }

        const handPos = convert_SFS_HAND_PROPS(hand);
        return {
            state: state.getSFSObject(hand),
            visible: (visibility & handPos.v) !== 0,
            seatPosition: handPos.s,
            bridgePosition:handPos.b,
            visibleWalkthru: visibleWalkthru,
        }
    }
     return undefined;
}