import { GlossaryEntry, Speed } from '../app/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getLocalstorage } from '../utils/mixed';

export type AppState = {
    apptoken: string;
    connection: {
        established: boolean;
        requested: boolean;
    };
    glossaryDictionary: GlossaryEntry[];
    isLoggedIn: boolean;
    loginError?: string | undefined;
    // modals: IModal[];
    requestLogin: boolean;
    requestLogout: boolean;
    settings: {
        fourColorCards: boolean;
        requiredNext: boolean;
        sound: boolean;
        speedCardAnimations: Speed;
        speedCards: Speed;
        speedScreens: Speed;
    };
    showSettings: boolean;
    token?: string | undefined;
    urlParams: Record<string, string>;
    user?: string | undefined;
    uuid?: string | undefined;
};

export const initialState: AppState = {
    apptoken: 'q9wfpprecaxqs07w13qn',
    connection: {
        established: false,
        requested: false,
    },
    glossaryDictionary: [],
    isLoggedIn: false,
    loginError: undefined,
    // modals: IModal[];
    requestLogin: false,
    requestLogout: false,
    settings: {
        ...{
            fourColorCards: false,
            requiredNext: false,
            sound: true,
            speedCardAnimations: Speed.instant,
            speedCards: Speed.medium,
            speedScreens: Speed.medium,
        },
        ...(getLocalstorage('betterBridgeSettings') ?? {}),
    },
    showSettings: false,
    token: undefined,
    urlParams: {},
    user: undefined,
    uuid: undefined,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        app_setConnection: (state, { payload }: PayloadAction<AppState['connection']>) => {
            state.connection = payload;
        },
        app_setGlossaryDictionary: (state, { payload }: PayloadAction<AppState['glossaryDictionary']>) => {
            state.glossaryDictionary = payload;
        },
        app_setIsLoggedIn: (state, { payload }: PayloadAction<AppState['isLoggedIn']>) => {
            state.isLoggedIn = payload;
        },
        app_setSettings: (state, { payload }: PayloadAction<Partial<AppState['settings']>>) => {
            state.settings = {
                ...state.settings,
                ...payload,
            };
        },
        app_setShowSettings: (state, { payload }: PayloadAction<AppState['showSettings']>) => {
            state.showSettings = payload;
        },
        app_setUrlParams: (state, { payload }: PayloadAction<AppState['urlParams']>) => {
            state.urlParams = payload;
        },
        app_reset: () => initialState,
    },
});

export const appActions = appSlice.actions;

// TODO: add some selectors
// export const selectHighlighted = (state: RootState) => state.card.highlighted;
// export const selectRaised = (state: RootState) => state.card.raised;
// export const selectRank = (state: RootState) => state.card.rank;
// export const selectSuit = (state: RootState) => state.card.suit;
// export const selectSuitSymbol = (state: RootState) => state.card.suitSymbol;
// export const selectVisible = (state: RootState) => state.card.visible;

export default appSlice.reducer;
