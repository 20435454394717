import './auction-line-component.scss';
import { AuctionComponent } from '../auction-component/auction-component';
import { BridgePosition } from '../../app/types';
import { RootState } from '../../app/store';
import React from 'react';

type AuctionLineComponentProps = {
    auction: RootState['table']['auction'];
    bridgePosition: BridgePosition | undefined;
    hidden?: boolean;
};

export const AuctionLineComponent: React.FC<AuctionLineComponentProps> = ({
    auction,
    bridgePosition,
    hidden = false,
}) => {
    const seatAuction = auction.filter((bid) => bid.bridgePosition === bridgePosition);
    return (
        <section className={`AuctionLineComponent ${hidden ? 'hidden' : ''}`}>
            {seatAuction.map((bid, index) => (
                <AuctionComponent key={`bid-${index}`} bid={bid} />
            ))}
        </section>
    );
};
