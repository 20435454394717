import {
    BiddingLadder,
    BonusIndicator,
    Call,
    Card,
    CardPosition,
    Cards,
    SeatData,
    Speed,
    Trick,
    ranks,
    suits,
} from './types';
import { getCardId } from '../utils/mixed';

export const defaultSeatData: SeatData = {
    background: undefined,
    bridgePosition: undefined,
    cardWidthMultiplier: 1,
    dummyCards: 0,
    dynamicCardColumnsWidth: false,
    isInteractive: false,
    isVertical: false,
    isVisible: false,
    labelPrimary: undefined,
    labelSecondary: undefined,
    labelTertiary: undefined,
    size: 'm',
};

export const defaultCard: Omit<Card, 'id' | 'rank' | 'suit'> = {
    bridgePosition: undefined,
    cardPosition: CardPosition.DECK,
    highlighted: false,
    raised: false,
    suitSymbol: false,
    visible: undefined,
};

export const defaultCards = (): Cards => {
    return suits.reduce((cards, suit) => {
        return ranks.reduce((cards, rank) => {
            const id = getCardId(suit, rank);
            return {
                ...cards,
                [id]: {
                    ...defaultCard,
                    id,
                    rank,
                    suit,
                },
            };
        }, cards);
    }, {} as Cards);
};

export const cardBackgrounds: string[] = ['card_set/cardback_blue.png', 'card_set/cardback_red.png'];

export const calls: Call[] = [
    '1c',
    '1d',
    '1h',
    '1s',
    '1nt',
    '2c',
    '2d',
    '2h',
    '2s',
    '2nt',
    '3c',
    '3d',
    '3h',
    '3s',
    '3nt',
    '4c',
    '4d',
    '4h',
    '4s',
    '4nt',
    '5c',
    '5d',
    '5h',
    '5s',
    '5nt',
    '6c',
    '6d',
    '6h',
    '6s',
    '6nt',
    '7c',
    '7d',
    '7h',
    '7s',
    '7nt',
];

export const defaultBiddingLadder: BiddingLadder = {
    bids: calls.reverse().map((call) => ({
        call,
        backgroundColor: undefined,
    })),
    bonusIndicators: [],
    firstVisibleCall: '1c',
    isActive: true,
    isVisible: false,
    minimalValidCall: '1c',
    showArrow: true,
    showBonusIndicator: true,
    stake: {
        call: 'xdbl',
        invisible: false,
        backgroundColor: undefined,
        disabled: true,
    },
    pass: {
        invisible: false,
        backgroundColor: undefined,
        disabled: false,
    },
};

export const allBonusIndicators: Record<BonusIndicator, Call[]> = {
    [BonusIndicator.g]: ['7c', '7d', '7h', '7s', '7nt'],
    [BonusIndicator.j]: ['3nt', '4h', '4s'],
    [BonusIndicator.m]: ['5c', '5d'],
    [BonusIndicator.s]: ['6c', '6d', '6h', '6s', '6nt'],
};

export const emptyTrick: Trick = {
    cardIds: [],
    winner: undefined,
};

export const cardAnimationSpeed: Record<Speed, number> = {
    [Speed.verySlow]: 1400,
    [Speed.slow]: 800,
    [Speed.medium]: 400,
    [Speed.fast]: 200,
    [Speed.veryFast]: 0,
    [Speed.instant]: 0,
};

export const speeds: Map<Speed, string> = new Map<Speed, string>([
    [Speed.verySlow, 'Very Slow'],
    [Speed.slow, 'Slow'],
    [Speed.medium, 'Medium'],
    [Speed.fast, 'Fast'],
    [Speed.veryFast, 'Very Fast'],
]);
