import './button-component.scss';
import { Button } from '../../slices/tableSlice';
import { classNames } from '../../utils/mixed';
import { outputActions } from '../../slices/outputSlice';
import { useAppDispatch } from '../../app/hooks';
import React from 'react';

export const ButtonComponent: React.FC<{ button: Button }> = ({ button: { label, highlighted, ...rest } }) => {
    const dispatch = useAppDispatch();
    const { output_setButton } = outputActions;
    return (
        <button
            className={classNames('ButtonComponent', highlighted ? 'highlighted' : '')}
            onClick={() => dispatch(output_setButton(rest))}
        >
            {label}
        </button>
    );
};
