import './seat-component.scss';
import { AuctionLineComponent } from '../auction-line-component/auction-line-component';
import { HandComponent } from '../hand-component/hand-component';
import { SeatPosition } from '../../app/types';
import { classNames, getCardsOfSeat } from '../../utils/mixed';
import { tableActions } from '../../slices/tableSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import React, { CSSProperties, useMemo } from 'react';

type SeatComponentProps = {
    seatPosition: SeatPosition;
};

export const SeatComponent: React.FC<SeatComponentProps> = ({ seatPosition }) => {
    const dispatch = useAppDispatch();
    const { auction, centerSeat, contract, dealer, seatData, suitOrder, showAuctionLine, userShowAuctionLine } =
        useAppSelector((state) => state.table);
    const cardsState = useAppSelector((state) => state.cards);
    const {
        background,
        bridgePosition,
        cardWidthMultiplier,
        dummyCards,
        dynamicCardColumnsWidth,
        isInteractive,
        isVertical,
        isVisible,
        labelPrimary,
        labelSecondary,
        labelTertiary,
        size,
    } = seatData[seatPosition];

    const cards = useMemo(() => {
        return bridgePosition ? getCardsOfSeat(cardsState, bridgePosition, contract, suitOrder) : [];
    }, [cardsState, suitOrder]);

    const trayStyle: CSSProperties = {
        backgroundColor: (background as string) ?? '#ccc',
    };

    const seatStyle = {
        '--seatCardWidthMultiplier': cardWidthMultiplier,
    } as React.CSSProperties;

    return (
        <section
            className={classNames(
                `SeatComponent seat-position-${seatPosition} size-${size}`,
                dealer === bridgePosition && 'dealer',
                centerSeat && 'center-seat',
                isVertical && 'isVertical',
            )}
            style={{ ...seatStyle }}
        >
            <div className={classNames('seat-hand', isVertical && 'isVertical')}>
                <HandComponent
                    {...{ cards, dummyCards, dynamicCardColumnsWidth, isInteractive, isVertical, isVisible }}
                />
            </div>
            <div className="tray" style={trayStyle}>
                <div className="label-container">
                    <div className="primary-label">{labelPrimary}</div>
                    <div className="secondary-label">{labelSecondary}</div>
                </div>
                <div
                    className="toggleAuctionLine"
                    onClick={() => dispatch(tableActions.table_toggleUserShowAuctionLine())}
                >
                    <AuctionLineComponent
                        {...{
                            bridgePosition,
                            auction,
                            hidden: !(userShowAuctionLine || showAuctionLine),
                        }}
                    />
                    {labelTertiary && (
                        <div
                            className={classNames(
                                'tertiary-label',
                                userShowAuctionLine && showAuctionLine ? 'hidden' : '',
                            )}
                        >
                            {labelTertiary}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};
