import './App.scss';
import { FooterComponent } from './features/footer-component/footer-component';
import { RootComponent } from './features/root-component/root-component';
import { environment } from './env';
import AudreyGameEngineInterface from './app-interfaces/game-engine-interface.audrey.class';
import React from 'react';
import SharkGameEngineInterface from './app-interfaces/game-engine-interface.shark.class';

function App() {
    return (
        <div className="App">
            {environment === 'audrey' ? <AudreyGameEngineInterface /> : <SharkGameEngineInterface />}
            <RootComponent />
            <FooterComponent />
        </div>
    );
}

export default App;
