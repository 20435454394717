import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import appReducer from '../slices/appSlice';
import cardsReducer from '../slices/cardsSlice';
import counterReducer from '../features/counter/counterSlice';
import outputReducer from '../slices/outputSlice';
import tableReducer from '../slices/tableSlice';

export const store = configureStore({
    reducer: {
        app: appReducer,
        cards: cardsReducer,
        table: tableReducer,
        output: outputReducer,
        // example
        counter: counterReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
