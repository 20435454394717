import './auction-component.scss';
import { Bid } from '../../app/types';
import {classNames} from '../../utils/mixed';
import {useAppSelector} from '../../app/hooks';
import React from 'react';

type AuctionComponentProps = {
    bid: Bid;
};

export const AuctionComponent: React.FC<AuctionComponentProps> = ({ bid }) => {
    const {
        walkThrough
    } = useAppSelector((state) => state.table);
    return (
        <section className={classNames('AuctionComponent', walkThrough && 'walkThrough')} style={{ backgroundColor: bid.backgroundColor }}>
            <img src={`/calls/${bid.call}.png`} alt={`${bid.call}`} />
        </section>
    );
};
