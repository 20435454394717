import {AppState} from '../slices/appSlice';
import { GameEngineInterface, mapDispatchToProps, mapStateToProps } from './game-engine-interface.class';
import {SFSObject, SmartFox} from 'sfs2x-api';
import { connect } from 'react-redux';

class AudreyGameEngineInterface extends GameEngineInterface {

    private bla = () => {
        console.log('test');
    };

    protected onLoginError = ({ errorMessage, errorCode }: { errorMessage: string; errorCode: number }) => {
        console.log('Better Bridge onLoginError : ',  errorMessage, errorCode);
        this.setState({ processingLogin: true });
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(AudreyGameEngineInterface);
