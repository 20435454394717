import { AppThunk } from '../app/store';
import {
    Auction,
    Bid,
    BiddingLadder,
    BridgePosition,
    CardId,
    DOMRectObj,
    SeatData,
    SeatPosition,
    SuitOrder,
    Trick,
    Vulnerable,
    WalkThrough,
} from '../app/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { cardActions } from './cardsSlice';
import { defaultBiddingLadder, defaultSeatData } from '../app/defaults';
import { getNextBridgePositionsClockwise } from '../utils/mixed';

type UpdateBid = Pick<Bid, 'call'> & Partial<Omit<Bid, 'call'>>;

export type TableInfoObject = {
    label: string;
    value: string;
    hidden?: boolean;
};

export type TableInfo = TableInfoObject | string | undefined;

export type AuxiliaryMessage = {
    text: string | undefined;
    title: string | undefined;
    titleColor: string | undefined;
    buttonOk: string | undefined;
    buttonCancel: string | undefined;
    buttonAuxiliary: string | undefined;
};

export type Button = {
    label: string;
    id: string;
    value: any;
    highlighted: boolean;
};

export type Tooltip = {
    name: string;
    boundingClientRect: DOMRectObj;
};

export type TableState = {
    active: BridgePosition | undefined;
    auction: Auction;
    auxiliaryMessages: {
        center: AuxiliaryMessage | undefined;
        left: AuxiliaryMessage | undefined;
        right: AuxiliaryMessage | undefined;
    };
    biddingLadder: BiddingLadder;
    bridgeTableHTML: string | undefined;
    bridgeTableVisibility: boolean;
    cardBackground: number;
    cardWidthMultiplier: number;
    centerSeat: boolean;
    contract: Bid | undefined;
    dealer: BridgePosition | undefined;
    declarer: BridgePosition | undefined;
    dummy: BridgePosition | undefined;
    ewtricks: number;
    footerButtons: (Button | undefined)[];
    forceShowAllCards: boolean;
    indexButtons: Button[];
    nstricks: number;
    player: BridgePosition | undefined;
    seatData: Record<SeatPosition, SeatData>;
    shouldCloseTrick: boolean;
    showAuctionLine: boolean;
    showClaimedTrick: number | undefined;
    showIndexButtons: boolean;
    showTrickCounter: boolean;
    suitOrder: SuitOrder;
    tableInfo: {
        left: TableInfo[] | undefined;
        right: TableInfo[] | undefined;
    };
    tooltip: Tooltip | undefined;
    tricks: Trick[];
    userShowAuctionLine: boolean;
    vulnerable: Vulnerable | undefined;
    walkThrough: WalkThrough | undefined;
    walkThroughIframe: string | undefined;
};

export const initialState: TableState = {
    active: undefined,
    auction: [],
    auxiliaryMessages: {
        center: undefined, // mockedAuxiliaryMessage,
        left: undefined, //mockedAuxiliaryMessage,
        right: undefined, //mockedAuxiliaryMessage,
    },
    biddingLadder: defaultBiddingLadder,
    bridgeTableHTML: undefined, //mockedCustomHtml,
    bridgeTableVisibility: false,
    cardBackground: 0,
    cardWidthMultiplier: 1,
    centerSeat: false,
    contract: undefined,
    dealer: undefined,
    declarer: undefined,
    dummy: undefined,
    ewtricks: 0,
    // footerButtons: mockedFooterButtons,
    footerButtons: [undefined, undefined, undefined, undefined, undefined, undefined, undefined],
    forceShowAllCards: false,
    // indexButtons: mockedFooterButtons as Button[],
    indexButtons: [],
    nstricks: 0,
    player: undefined,
    seatData: {
        [SeatPosition.top]: defaultSeatData,
        [SeatPosition.right]: defaultSeatData,
        [SeatPosition.bottom]: defaultSeatData,
        [SeatPosition.left]: defaultSeatData,
    },
    shouldCloseTrick: false,
    showAuctionLine: false,
    showClaimedTrick: undefined,
    showIndexButtons: false,
    suitOrder: SuitOrder.SHCD,
    tableInfo: {
        left: undefined,
        right: undefined,
    },
    tooltip: undefined,
    tricks: [],
    showTrickCounter: false,
    userShowAuctionLine: false,
    vulnerable: undefined,
    walkThrough: undefined,
    walkThroughIframe: undefined,
};

export const tableSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        table_addTrick: (state, { payload }: PayloadAction<Trick>) => {
            state.tricks = [...state.tricks, payload];
        },
        table_claimTrick: (state, { payload }: PayloadAction<BridgePosition>) => {
            const lastTrickIndex = state.tricks.length - 1;
            state.tricks[lastTrickIndex] = {
                ...state.tricks[lastTrickIndex],
                winner: payload,
            };
        },
        table_playCard: (state, { payload }: PayloadAction<CardId>) => {
            const lastTrickIndex = state.tricks.length - 1;
            state.tricks[lastTrickIndex] = {
                ...state.tricks[lastTrickIndex],
                cardIds: [...state.tricks[lastTrickIndex].cardIds, payload],
            };
        },
        table_setActive: (state, { payload }: PayloadAction<TableState['active']>) => {
            state.active = payload;
        },
        table_setAuction: (state, { payload }: PayloadAction<TableState['auction']>) => {
            state.auction = payload;
        },
        table_setAuxiliaryMessage: (
            state,
            {
                payload: { position, message },
            }: PayloadAction<{
                position: keyof TableState['auxiliaryMessages'];
                message: AuxiliaryMessage | undefined;
            }>,
        ) => {
            state.auxiliaryMessages = {
                ...state.auxiliaryMessages,
                [position]: message,
            };
        },
        table_setBiddingLadder: (state, { payload }: PayloadAction<TableState['biddingLadder']>) => {
            state.biddingLadder = payload;
        },
        table_setBridgeTableHTML: (state, { payload }: PayloadAction<TableState['bridgeTableHTML']>) => {
            state.bridgeTableHTML = payload;
        },
        table_setBridgeTableVisibility: (state, { payload }: PayloadAction<TableState['bridgeTableVisibility']>) => {
            state.bridgeTableVisibility = payload;
        },
        table_setCardBackground: (state, { payload }: PayloadAction<TableState['cardBackground']>) => {
            state.cardBackground = payload;
        },
        table_setCardWidthMultiplier: (state, { payload }: PayloadAction<TableState['cardWidthMultiplier']>) => {
            state.cardWidthMultiplier = payload;
        },
        table_setCenterSeat: (state, { payload }: PayloadAction<TableState['centerSeat']>) => {
            state.centerSeat = payload;
        },
        table_setContract: (state, { payload }: PayloadAction<TableState['contract']>) => {
            state.contract = payload;
        },
        table_setDealer: (state, { payload }: PayloadAction<TableState['dealer']>) => {
            state.dealer = payload;
        },
        table_setDeclarer: (state, { payload }: PayloadAction<TableState['declarer']>) => {
            state.declarer = payload;
        },
        table_setDummy: (state, { payload }: PayloadAction<TableState['dummy']>) => {
            state.dummy = payload;
        },
        table_setFooterButtons: (state, { payload }: PayloadAction<TableState['footerButtons']>) => {
            state.footerButtons = payload;
        },
        table_setForceShowAllCards: (state, { payload }: PayloadAction<TableState['forceShowAllCards']>) => {
            state.forceShowAllCards = payload;
        },
        table_setIndexButtons: (state, { payload }: PayloadAction<TableState['indexButtons']>) => {
            state.indexButtons = payload;
        },
        table_setPlayer: (state, { payload }: PayloadAction<TableState['player']>) => {
            state.player = payload;
        },
        table_setPrimaryLabelToBridgePosition: (state) => {
            state.seatData[SeatPosition.top].labelPrimary = state.seatData[SeatPosition.top].bridgePosition;
            state.seatData[SeatPosition.right].labelPrimary = state.seatData[SeatPosition.right].bridgePosition;
            state.seatData[SeatPosition.bottom].labelPrimary = state.seatData[SeatPosition.bottom].bridgePosition;
            state.seatData[SeatPosition.left].labelPrimary = state.seatData[SeatPosition.left].bridgePosition;
        },
        table_setSeatData: (state, { payload }: PayloadAction<{ seatPosition: SeatPosition } & Partial<SeatData>>) => {
            const { seatPosition, ...seatData } = payload;
            state.seatData = {
                ...state.seatData,
                [seatPosition]: {
                    ...state.seatData[seatPosition],
                    ...seatData,
                },
            };
        },
        table_setShowAuctionLine: (state, { payload }: PayloadAction<TableState['showAuctionLine']>) => {
            state.showAuctionLine = payload;
        },
        table_setShowTrickCounter: (state, { payload }: PayloadAction<TableState['showTrickCounter']>) => {
            state.showTrickCounter = payload;
        },
        table_setSuitOrder: (state, { payload }: PayloadAction<TableState['suitOrder']>) => {
            state.suitOrder = payload;
        },
        table_setTopBridgePosition: (state, { payload }: PayloadAction<BridgePosition>) => {
            const bridgePosition = payload;
            state.seatData = {
                [SeatPosition.top]: {
                    ...state.seatData[SeatPosition.top],
                    bridgePosition: bridgePosition,
                },
                [SeatPosition.right]: {
                    ...state.seatData[SeatPosition.right],
                    bridgePosition: getNextBridgePositionsClockwise[bridgePosition][0],
                },
                [SeatPosition.bottom]: {
                    ...state.seatData[SeatPosition.bottom],
                    bridgePosition: getNextBridgePositionsClockwise[bridgePosition][1],
                },
                [SeatPosition.left]: {
                    ...state.seatData[SeatPosition.left],
                    bridgePosition: getNextBridgePositionsClockwise[bridgePosition][2],
                },
            };
        },
        table_setTableInfo: (state, { payload }: PayloadAction<TableState['tableInfo']>) => {
            state.tableInfo = payload;
        },
        table_setTooltip: (state, { payload }: PayloadAction<TableState['tooltip']>) => {
            state.tooltip = payload;
        },
        table_setTricks: (state, { payload }: PayloadAction<TableState['tricks']>) => {
            state.tricks = payload;
        },
        table_setTrickCounts: (
            state,
            { payload }: PayloadAction<{ ns: TableState['nstricks']; ew: TableState['ewtricks'] }>,
        ) => {
            state.nstricks = payload.ns;
            state.ewtricks = payload.ew;
        },
        table_setVulnerable: (state, { payload }: PayloadAction<TableState['vulnerable']>) => {
            state.vulnerable = payload;
        },
        table_setWalkTrough: (state, { payload }: PayloadAction<TableState['walkThrough']>) => {
            state.walkThrough = payload;
        },
        table_setWalkThroughIframe: (state, { payload }: PayloadAction<TableState['walkThroughIframe']>) => {
            state.walkThroughIframe = payload;
        },
        table_shouldCloseTrick: (state, { payload }: PayloadAction<TableState['shouldCloseTrick']>) => {
            state.shouldCloseTrick = payload;
        },
        table_showClaimedTrick: (state, { payload }: PayloadAction<TableState['showClaimedTrick']>) => {
            state.showClaimedTrick = state.showClaimedTrick === payload ? undefined : payload;
        },
        table_toggleUserShowAuctionLine: (state) => {
            state.userShowAuctionLine = !state.userShowAuctionLine;
        },
        table_updateAuctionBids: (state, { payload }: PayloadAction<UpdateBid[]>) => {
            state.auction = getUpdatedBids(state.auction, payload);
        },
        table_updateBiddingLadder: (state, { payload }: PayloadAction<Partial<TableState['biddingLadder']>>) => {
            state.biddingLadder = {
                ...state.biddingLadder,
                ...payload,
            };
        },
        table_updateBiddingLadderBids: (state, { payload }: PayloadAction<UpdateBid[]>) => {
            state.biddingLadder = {
                ...state.biddingLadder,
                bids: getUpdatedBids(state.biddingLadder.bids, payload),
            };
        },
        table_updateFooterButtons: (state, { payload }: PayloadAction<{ index: number; button: Button }[]>) => {
            payload.forEach(({ index, button }) => {
                state.footerButtons[index] = button;
            });
        },
        table_updateSeatData: (
            state,
            {
                payload: { seatPosition, seatData },
            }: PayloadAction<{ seatPosition: SeatPosition; seatData: Partial<SeatData> }>,
        ) => {
            state.seatData = {
                ...state.seatData,
                [seatPosition]: {
                    ...state.seatData[seatPosition],
                    ...seatData,
                },
            };
        },
        table_updateTableInfo: (
            state,
            {
                payload: { side, info },
            }: PayloadAction<{ side: keyof TableState['tableInfo']; info: TableState['tableInfo']['left'] }>,
        ) => {
            state.tableInfo = {
                ...state.tableInfo,
                [side]: info,
            };
        },
        table_updateTableInfoLine: (
            state,
            {
                payload: { side, info, index },
            }: PayloadAction<{ side: keyof TableState['tableInfo']; info: TableInfo; index: number }>,
        ) => {
            state.tableInfo[side]![index] = info;
        },
        table_updateTrick: (state, { payload: { index, trick } }: PayloadAction<{ index: number; trick: Trick }>) => {
            if (index < 0) {
                state.tricks[state.tricks.length + index] = trick;
            } else {
                state.tricks[index] = trick;
            }
        },
        // generic partial table update
        table_updateTable: (state, { payload }: PayloadAction<Partial<TableState>>) => {
            return {
                ...state,
                ...payload,
            };
        },
        // all the reset stuff
        table_reset: () => initialState,
        table_resetPartial: (state, { payload }: PayloadAction<(keyof TableState)[]>) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            payload.forEach((tableStateKey) => (state[tableStateKey] = initialState[tableStateKey]));
        },
        table_resetBiddingLadderBids: (state) => {
            state.biddingLadder.bids = defaultBiddingLadder.bids;
        },
    },
});

export const tableActions = tableSlice.actions;

const getUpdatedBids = (currentBids: Bid[], updateBids: UpdateBid[]): Bid[] => {
    const updatedBids = currentBids.map((currentBid) => {
        const updateBid = updateBids.find((_updateBid) => _updateBid.call === currentBid.call);
        if (updateBid) {
            return {
                ...currentBid,
                ...updateBid,
            };
        }
        return currentBid;
    });
    return updatedBids;
};

export const playCard =
    (cardId: CardId): AppThunk =>
    (dispatch) => {
        dispatch(tableActions.table_playCard(cardId));
        dispatch(cardActions.cards_playCard(cardId));
    };

// TODO: add some selectors
// export const selectHighlighted = (state: RootState) => state.card.highlighted;
// export const selectRaised = (state: RootState) => state.card.raised;
// export const selectRank = (state: RootState) => state.card.rank;
// export const selectSuit = (state: RootState) => state.card.suit;
// export const selectSuitSymbol = (state: RootState) => state.card.suitSymbol;
// export const selectVisible = (state: RootState) => state.card.visible;

export default tableSlice.reducer;
