import './walk-through-component.scss';
import { ButtonComponent } from '../button-component/button-component';
import {handleGlossaryAndQuizActions, handleGlossaryMouseOut} from '../../utils/mixed';
import { outputActions } from '../../slices/outputSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import React from 'react';

export const WalkThroughComponent: React.FC = () => {
    const { indexButtons, showIndexButtons, walkThrough, walkThroughIframe } = useAppSelector((state) => state.table);
    const dispatch = useAppDispatch();
    const { output_setWalkThruTarget } = outputActions;

    return (
        <section
            className="WalkThroughComponent"
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
            }}
            onTouchStart = {(event) => {
                event.preventDefault();
                event.stopPropagation();
                dispatch(output_setWalkThruTarget(handleGlossaryAndQuizActions(event,true)));
            }}

            onMouseDown = {(event) => {
                event.preventDefault();
                event.stopPropagation();
                dispatch(output_setWalkThruTarget(handleGlossaryAndQuizActions(event, true)));
            }}

            onMouseOver= {(event) => {
                event.preventDefault();
                event.stopPropagation();
                dispatch(output_setWalkThruTarget(handleGlossaryAndQuizActions(event,false)));
            }}

            onTouchEnd = {(event) => {
                event.preventDefault();
                event.stopPropagation();
                dispatch(output_setWalkThruTarget(handleGlossaryMouseOut(event)));
                //Close the tool tip
            }}

            onMouseOut = {(event) => {
                event.preventDefault();
                event.stopPropagation();
                dispatch(output_setWalkThruTarget(handleGlossaryMouseOut(event)));
                //Close the tool tip
            }}
        >
            <div className="content" dangerouslySetInnerHTML={{ __html: walkThrough?.content ?? '' }} />
            {walkThroughIframe && (
                <div className="walk-through-iframe">
                    <iframe src={walkThroughIframe} />
                </div>
            )}
            {showIndexButtons && (
                <div className="index-buttons">
                    {indexButtons.map((button, index) => (
                        <ButtonComponent key={`walkThroughButtons-${index}`} button={button} />
                    ))}
                </div>
            )}
        </section>
    );
};
