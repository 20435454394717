import './footer-component.scss';
import { ButtonComponent } from '../button-component/button-component';
import { appActions } from '../../slices/appSlice';
import { outputActions } from '../../slices/outputSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import React from 'react';

export const FooterComponent: React.FC = () => {
    const { footerButtons, forceShowAllCards } = useAppSelector((state) => state.table);
    const dispatch = useAppDispatch();
    const { output_setButton } = outputActions;
    const { app_setShowSettings } = appActions;

    return (
        <footer className="FooterComponent">
            {footerButtons.map((button, index) => (
                <div key={`footerButton-${index}`}>{button && <ButtonComponent button={button} />}</div>
            ))}
            <div className="settings" onClick={() => dispatch(app_setShowSettings(true))}>
                <img src="./images/settings30_30.png" />
            </div>
            <div className="card-button" onClick={() => dispatch(output_setButton({ id: 'showAll', value: true }))}>
                {forceShowAllCards ? <img src="./card_set/cardback_blue.png" /> : <img src="./card_set/sk.png" />}
            </div>
        </footer>
    );
};
