import {
    BridgePosition,
    Call,
    Card,
    CardId,
    CardPosition,
    GlossaryEntry,
    Rank,
    Suit,
    SuitOrder,
    ranks,
} from '../app/types';
import { RootState } from '../app/store';
import React, { CSSProperties } from 'react';

export const shortRank: Record<Rank, string> = {
    [Rank.ace]: 'a',
    [Rank.king]: 'k',
    [Rank.queen]: 'q',
    [Rank.jack]: 'j',
    [Rank.ten]: 't',
    [Rank.nine]: '9',
    [Rank.eight]: '8',
    [Rank.seven]: '7',
    [Rank.six]: '6',
    [Rank.five]: '5',
    [Rank.four]: '4',
    [Rank.three]: '3',
    [Rank.two]: '2',
};

export const shortSuit: Record<Suit, string> = {
    [Suit.hearts]: 'h',
    [Suit.spades]: 's',
    [Suit.clubs]: 'c',
    [Suit.diamonds]: 'd',
};

export const getCardId = (suit: Suit, rank: Rank): CardId => {
    return `${shortSuit[suit]}${shortRank[rank]}` as CardId;
};

export const getSuitFromCardId = (id: CardId): Suit | undefined => {
    return (Object.keys(shortSuit) as Suit[]).find((key) => shortSuit[key] === id.substring(0, 1));
};

export const getRankFromCardId = (id: CardId): Rank | undefined => {
    return (Object.keys(shortRank) as Rank[]).find((key) => shortRank[key] === id.substring(1));
};

export const cardIdArray: CardId[] = [
    'c2',
    'c3',
    'c4',
    'c5',
    'c6',
    'c7',
    'c8',
    'c9',
    'ca',
    'cj',
    'ck',
    'cq',
    'ct',
    'd2',
    'd3',
    'd4',
    'd5',
    'd6',
    'd7',
    'd8',
    'd9',
    'da',
    'dj',
    'dk',
    'dq',
    'dt',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'h7',
    'h8',
    'h9',
    'ha',
    'hj',
    'hk',
    'hq',
    'ht',
    's2',
    's3',
    's4',
    's5',
    's6',
    's7',
    's8',
    's9',
    'sa',
    'sj',
    'sk',
    'sq',
    'st',
];

export const getCardsByBridgePosition = (cards: RootState['cards'], bridgePosition: BridgePosition): Card[] => {
    return (Object.keys(cards) as CardId[])
        .filter((id) => cards[id].bridgePosition === bridgePosition)
        .map((id) => cards[id]);
};

export const getNextBridgePositionsClockwise: Record<BridgePosition, BridgePosition[]> = {
    [BridgePosition.north]: [BridgePosition.east, BridgePosition.south, BridgePosition.west],
    [BridgePosition.east]: [BridgePosition.south, BridgePosition.west, BridgePosition.north],
    [BridgePosition.south]: [BridgePosition.west, BridgePosition.north, BridgePosition.east],
    [BridgePosition.west]: [BridgePosition.north, BridgePosition.east, BridgePosition.south],
};

export const suitOrders: Record<SuitOrder, Suit[]> = {
    [SuitOrder.CDSH]: [Suit.clubs, Suit.diamonds, Suit.spades, Suit.hearts],
    [SuitOrder.CHSD]: [Suit.clubs, Suit.hearts, Suit.spades, Suit.diamonds],
    [SuitOrder.DCHS]: [Suit.diamonds, Suit.clubs, Suit.hearts, Suit.spades],
    [SuitOrder.DSHC]: [Suit.diamonds, Suit.spades, Suit.hearts, Suit.clubs],
    [SuitOrder.HCDS]: [Suit.hearts, Suit.clubs, Suit.diamonds, Suit.spades],
    [SuitOrder.HSDC]: [Suit.hearts, Suit.spades, Suit.diamonds, Suit.clubs],
    [SuitOrder.SDCH]: [Suit.spades, Suit.diamonds, Suit.clubs, Suit.hearts],
    [SuitOrder.SHCD]: [Suit.spades, Suit.hearts, Suit.clubs, Suit.diamonds],
    [SuitOrder.ENGINE]: [],
};

export const sortCards = (cards: Card[], suitOrder: SuitOrder): Card[] => {
    return cards.sort((cardA, cardB): number => {
        const suits: Suit[] | undefined = suitOrders[suitOrder] ?? suitOrders[SuitOrder.SHCD];
        const indexA = suits.indexOf(cardA.suit);
        const indexB = suits.indexOf(cardB.suit);
        if (indexA < indexB) {
            return -1;
        } else if (indexA > indexB) {
            return 1;
        } else {
            return ranks.indexOf(cardA.rank) - ranks.indexOf(cardB.rank);
        }
    });
};

export function classNames(...classes: (boolean | null | undefined | string)[]): string {
    return classes.filter(Boolean).join(' ');
}

export const getLevelStrainFromCall = (call: Call): { level: number | undefined; strain: string } => {
    const firstChar = call.substring(0, 1);
    const rest = call.substring(1);
    if (isFinite(Number(firstChar))) {
        return {
            level: Number(firstChar),
            strain: rest,
        };
    }
    return {
        level: undefined,
        strain: call,
    };
};

export const enumToArray = (E: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
    const keys = Object.keys(E).filter((k) => typeof E[k as any] === 'number');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return !keys.length ? Object.keys(E) : keys;
};

export const cssVars = (_cssVars: Record<string, string | number>) => {
    return _cssVars as CSSProperties;
};

export function getRandomInt(max: number): number {
    return Math.floor(Math.random() * max);
}

export function getGroupedCards(cards: Card[]): Card[][] {
    return cards.reduce<Card[][]>((_groupedCards, currCard) => {
        const lastSuit = (_groupedCards[_groupedCards.length - 1] ?? [])[0]?.suit;
        if (lastSuit !== currCard.suit) {
            _groupedCards.push([currCard]);
        } else {
            _groupedCards[_groupedCards.length - 1].push(currCard);
        }
        return _groupedCards;
    }, []);
}

export function getMaxCardsPerGroup(cards: Card[]): number {
    return getGroupedCards(cards).reduce<number>((maxCards, currGroup) => {
        return Math.max(maxCards, currGroup.length);
    }, 0);
}

export const getCardsOfSeat = (
    cards: RootState['cards'],
    bridgePosition: BridgePosition,
    contract: RootState['table']['contract'],
    suitOrder: RootState['table']['suitOrder'],
): Card[] => {
    const cardsArray = getCardsByBridgePosition(cards, bridgePosition).filter(
        (card) => card.cardPosition === CardPosition.HAND,
    );

    if (suitOrder === SuitOrder.ENGINE) {
        if (contract) {
            const { strain } = getLevelStrainFromCall(contract.call);
            if (strain === 's') {
                return sortCards(cardsArray, SuitOrder.SHCD);
            } else if (strain === 'h') {
                return sortCards(cardsArray, SuitOrder.HSDC);
            } else if (strain === 'd') {
                return sortCards(cardsArray, SuitOrder.DSHC);
            } else if (strain === 'c') {
                return sortCards(cardsArray, SuitOrder.CHSD);
            }
            return sortCards(cardsArray, SuitOrder.SHCD);
        }
        return sortCards(cardsArray, SuitOrder.SHCD);
    }
    return sortCards(cardsArray, suitOrder);
};

export const handleGlossaryAndQuizActions = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
    clicked: boolean,
) => {
    const element = event.target as HTMLElement;
    const anchor = event.target as HTMLAnchorElement;
    const button = event.target as HTMLButtonElement;

    let target, value;

    if (button.classList.contains('quiz_submit_OK')) {
        if (clicked) {
            target = 'quiz_submit_OK';
        }
        value = undefined;
    } else if (anchor.hasAttribute('href')) {
        target = 'link';
        value = anchor.getAttribute('href') ?? undefined;
    }

    //console.log('element.getBoundingClientRect()', element.getBoundingClientRect());

    return {
        value,
        target,
        boundingClientRect: { ...element.getBoundingClientRect() },
    };
};

export const handleGlossaryMouseOut = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
    const element = event.target as HTMLElement;
    const anchor = event.target as HTMLAnchorElement;
    // const button = event.target as HTMLButtonElement;

    let value;
    const target = 'cancel';
    if (anchor.hasAttribute('href')) {
        value = anchor.getAttribute('href') ?? undefined;
    }

    return {
        value,
        target,
        boundingClientRect: { ...element.getBoundingClientRect() },
    };
};

export const setLocalstorage = (key: string, value: any) => {
    window.localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalstorage = (key: string) => {
    try {
        const item = window.localStorage.getItem(key);
        return item ? (JSON.parse(item) as { dictionary: GlossaryEntry[]; timestamp: number }) : undefined;
    } catch (error) {
        console.log(error);
        return undefined;
    }
};
