import './card-component.scss';
import { BridgePosition, Card, SeatData } from '../../app/types';
import { cardBackgrounds } from '../../app/defaults';
import { classNames } from '../../utils/mixed';
import { outputActions } from '../../slices/outputSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import React, { HTMLAttributes, useCallback, useState } from 'react';

export type CardComponentProps = {
    card: Card;
    hand: Pick<SeatData, 'isInteractive' | 'isVisible'>;
};

export const CardComponent: React.FC<CardComponentProps> = ({
    card,
    hand: { isVisible: handIsVisible, isInteractive: handIsInteractive },
}) => {
    const { forceShowAllCards } = useAppSelector((state) => state.table);
    const { highlighted, visible } = card;
    const [userHighlighted, setUserHighlighted] = useState(false);
    const showCardBack = forceShowAllCards ? false : visible === false || (visible === undefined && !handIsVisible);
    const onUserHighlight = useCallback(() => {
        if (!showCardBack && !highlighted) {
            setUserHighlighted(true);
        }
    }, [showCardBack, highlighted]);
    const onUserDeHighlight = useCallback(() => {
        if (!showCardBack && !highlighted) {
            setUserHighlighted(false);
        }
    }, [showCardBack, highlighted]);
    const props: HTMLAttributes<HTMLDivElement> = {
        ...(handIsInteractive
            ? { onMouseDown: onUserHighlight, onMouseUp: onUserDeHighlight, onMouseLeave: onUserDeHighlight }
            : {}),
    };
    return showCardBack ? (
        <CardBackComponent />
    ) : (
        <section className="CardComponent" {...props}>
            <CardFaceComponent
                card={card}
                highlighted={highlighted || userHighlighted}
                isInteractive={handIsInteractive}
            />
        </section>
    );
};

export const CardFaceComponent: React.FC<{
    highlighted?: boolean;
    card: Card;
    isInteractive: boolean;
}> = ({ highlighted, card, isInteractive }) => {
    const dispatch = useAppDispatch();
    const { output_setCard } = outputActions;
    const {
        settings: { fourColorCards },
    } = useAppSelector((state) => state.app);
    return (
        <div
            className={classNames('card face', highlighted && 'highlighted', isInteractive && 'interactive')}
            onClick={() => isInteractive && dispatch(output_setCard(card))}
            style={{
                backgroundImage: `url("card_set/${fourColorCards ? 'color/' : ''}${card.id}.png")`,
            }}
        />
    );
};

export const CardBackComponent: React.FC<{ bridgePosition?: BridgePosition }> = ({ bridgePosition }) => {
    const { cardBackground } = useAppSelector((state) => state.table);
    return (
        <section className={`CardComponent bridgePosition-${bridgePosition}`}>
            <div
                className="card"
                style={{ backgroundImage: `url("${cardBackgrounds[cardBackground] || cardBackgrounds[0]}")` }}
            />
        </section>
    );
};

export const CardPlaceholderComponent: React.FC = () => {
    return (
        <section className="CardComponent">
            <div className={classNames('card', 'noBackground')} />
        </section>
    );
};
