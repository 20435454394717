import { Card, CardId, CardPosition, Cards } from '../app/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { defaultCards } from '../app/defaults';

export type CardsState = Cards;

export const initialState: CardsState = defaultCards();

export type CardUpdateProps = Pick<Card, 'id'> & Partial<Omit<Card, 'id' | 'rank' | 'suit'>>;

export const cardsSlice = createSlice({
    name: 'cards',
    initialState,
    reducers: {
        cards_playCard: (state, { payload: cardId }: PayloadAction<CardId>) => {
            state[cardId] = {
                ...state[cardId],
                cardPosition: CardPosition.TRICK,
            };
        },
        cards_setCards: (
            state,
            // action: PayloadAction<Card[]>,
            action: PayloadAction<CardUpdateProps[]>,
        ) => {
            return action.payload.reduce((cards, card) => {
                // TODO: handle dependency between suit/rank === undefined and visible === false
                return {
                    ...cards,
                    [card.id]: {
                        ...cards[card.id],
                        ...card,
                    },
                };
            }, state);
        },
        cards_reset: () => initialState,
    },
});

export const cardActions = cardsSlice.actions;

// TODO: add some selectors
// export const selectHighlighted = (state: RootState) => state.card.highlighted;
// export const selectRaised = (state: RootState) => state.card.raised;
// export const selectRank = (state: RootState) => state.card.rank;
// export const selectSuit = (state: RootState) => state.card.suit;
// export const selectSuitSymbol = (state: RootState) => state.card.suitSymbol;
// export const selectVisible = (state: RootState) => state.card.visible;

export default cardsSlice.reducer;
