/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppState, appActions } from '../slices/appSlice';
import {
    Auction,
    Bid,
    BonusIndicator,
    BridgePosition,
    Call,
    Card,
    CardId,
    CardPosition,
    ClickEventData, SeatData,
    SeatPosition,
} from '../app/types';
import { AuxiliaryMessage, TableInfo, TableState, tableActions } from '../slices/tableSlice';
import {CMD, SFSVAR, SFS_EXT, SFS_HAND_PROPS, SFS_HAND_STATE, SFS_NAV} from './sfsVar';
import { CardUpdateProps, cardActions } from '../slices/cardsSlice';
import {
    ExtensionRequest,
    LoginRequest,
    LogoutRequest,
    SFSArray,
    SFSEvent,
    SFSObject,
    SFSRoom,
    SFSUser,
    SmartFox,
} from 'sfs2x-api';
import { RootState } from '../app/store';
import {
    bridgePosToSeatPos,
    convertBridgePositionToInt,
    convertIntToBridgePosition,
    convertIntToCall,
    fixColor,
    getCardState,
    intToSuitOrder,
    sizeToCardMultiplier,
    strToCall,
    strToCardId,
    strToSize,
} from './game-engine-helper';
import {cardSetFromString, getSFSHandState, seatDataFromSFSObject, tableInfoFromSFSObject} from './handState';
import { connect } from 'react-redux';
import { emptyTrick } from '../app/defaults';
import { outputActions } from '../slices/outputSlice';
import React, { useDebugValue } from 'react';

type GameEngineInterfaceProps = StateProps & DispatchProps;

type GameEngineInterfaceState = {
    currentPlayer: number | undefined;
    farewellMessage: string | undefined;
    isSharkZone: boolean;
    isMiniBridge: boolean;
    lag: number;
    lobbyRoom: SFSRoom | undefined;
    processingLogin: boolean;
    urlParams:Record<string, string> | undefined;
};

export class GameEngineInterface extends React.Component<GameEngineInterfaceProps, GameEngineInterfaceState> {
    protected consoleOut = false;

    protected config = {
        host: 'sfs03.emabridge.com',
        port: 8443,
        useSSL: true,
        zone: 'BetterBridgeClub',
    };

    protected sfs: SmartFox;
    protected indexButtons: TableState['indexButtons'] = [];

    protected favurl?: string = undefined;

    protected forceShowAllCards = false;

    constructor(props: GameEngineInterfaceProps) {
        super(props);
        this.configureSFS();
        this.sfs = new SmartFox(this.config);
        this.addSFSListeners();
        //Establish Connection
        this.sfs.connect();
    }

    public state: GameEngineInterfaceState = {
        currentPlayer: undefined,
        farewellMessage: undefined, // 'This was the last hand in this set!'
        isSharkZone: false,
        isMiniBridge: false,
        lag: 10,
        lobbyRoom: undefined,
        processingLogin: false,
        urlParams:undefined
    };

    protected messageEvent = window.addEventListener('message', (event) => {
        // IMPORTANT: check the origin of the data!
        //console.log("Received Message, ", event);
        if (event.data === 'closefav') {
            // console.log('Received Message, ', event);
            this.props.table_setWalkThroughIframe(undefined);
        }
    });

    protected storeUrlParams = () => {
        const urlHasParams = window.location.href.indexOf('?') !== -1;
        const urlParamsString = urlHasParams ? window.location.href.substr(window.location.href.indexOf('?') + 1) : '';
        const searchParams = new URLSearchParams(urlParamsString);

        const urlParams = [...searchParams.entries()].reduce<AppState['urlParams']>((cumulated, current) => {
            return { ...cumulated, [current[0]]: current[1] };
        }, {});

        if (Object.keys(urlParams).length) {
            this.props.app_setUrlParams(urlParams);
        }
    };

    //  protected sfs: any = undefined;
    public componentDidUpdate(
        prevProps: Readonly<GameEngineInterfaceProps>,
        prevState: Readonly<GameEngineInterfaceState>,
        snapshot?: any,
    ): void {
        // states
        const { app, output } = this.props;
        // output actions
        const { output_reset } = this.props;
        const { user, token, uuid, requestLogin, requestLogout, connection } = app;

        if (
            prevProps.app.settings.speedCards !== app.settings.speedCards ||
            prevProps.app.settings.requiredNext !== app.settings.requiredNext ||
            prevProps.app.settings.speedScreens !== app.settings.speedScreens
        ) {
            // do something
            this.clientRequestsSettingsUpdate();
            this.props.output_reset();
        } else if (!prevProps.output.button && output.button) {
            // console.log("user action : ", output.button);
            switch (output.button.id) {
                case 'next':
                    this.props.output_reset();
                    this.clientRequestsNextPoint();
                    break;
                case 'nexthand':
                    this.props.output_reset();
                    this.clientRequestsNextHand();
                    break;
                case 'back':
                    this.props.output_reset();
                    this.clientRequestsPreviousPoint();
                    break;
                case 'playDeal':
                    this.props.output_reset();
                    this.clientRequestsNextPoint();
                    break;
                case 'practice':
                    this.props.output_reset();
                    this.clientRequestsTraining();
                    break;
                case 'autoBid':
                    this.props.output_reset();
                    this.clientRequestsAutoBid();
                    break;
                case 'answer':
                    this.props.output_reset();
                    this.clientRequestsAnswer();
                    break;
                case 'undo':
                    this.props.output_reset();
                    this.clientRequestsUndo();
                    break;
                case 'replay':
                    this.props.output_reset();
                    this.clientRequestsReplay();
                    break;
                case 'skip':
                    this.props.output_reset();
                    this.clientRequestsSkip();
                    break;
                case 'favorite':
                    this.props.output_reset();
                    this.clientRequestsFav('Some notes');
                    break;
                case 'claim':
                    this.props.output_reset();
                    this.clientRequestsClaim();
                    break;
                case 'acceptClaim':
                    this.props.output_reset();
                    this.clientRequestsAcceptClaim();
                    break;
                case 'auxiliaryCancel':
                    this.props.output_reset();
                    this.props.table_setAuxiliaryMessage({ position: 'center', message: undefined });
                    if (!this.state.lobbyRoom) {
                        this.props.table_setAuxiliaryMessage({ position: 'right', message: undefined });
                        this.props.table_setAuxiliaryMessage({ position: 'left', message: undefined });
                    }
                    // this.clientRequestsAcceptClaim();
                    break;
                case 'auxiliaryOk':
                    this.props.output_reset();
                    this.clientRequestsNextPoint();
                    break;
                case 'auxiliaryAuxiliary':
                    this.props.output_reset();
                    this.clientRequestsAcceptClaim();
                    break;
                case 'dashboard':
                    this.props.output_reset();
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore

                    parent.postMessage('reloaddash', '*');
                    break;
                case 'closeTrick': {
                    this.clientRequestsCloseTrick();
                    break;
                }
                case 'index': {
                    this.toggleIndexMenu();
                    break;
                }
                case 'indexButton': {
                    this.clientRequestsTopicAtIndex(output.button.value as number);
                    break;
                }
                case 'showAll': {
                    this.clientRequestToggleVisibility();
                    break;
                }

                default:
                    console.warn('pressed button is unhandled');
            }
        }

        //HTML Component click, catch clicked target and deal with it.
        if (!prevProps.output.clickEventData && output.clickEventData) {
            this.clientRequestsHTMLLink(output.clickEventData);
        }

        // MAKE CALL
        else if (prevProps.output.bid !== output.bid && output.bid) {
            this.clientRequestsMakeCall(output.bid);
        }

        //MAKE PLAY
        else if (prevProps.output.card !== output.card && output.card) {
            this.clientRequestsMakePlay(output.card);
        }

        // DEAL CARD
        // if (prevProps.output.dealCard !== output.dealCard && output.dealCard) {
        //     this.clientRequestsDealCard(output.dealCard);
        // }

        // Send Chat Messsage
        // if (prevProps.output.chatEntry !== output.chatEntry && output.chatEntry) {
        //     this.sendChatMessage(output.chatEntry);
        // }

        // Request Director
        // if (prevProps.output.director !== output.director && output.director != null) {
        //     //  debugger;
        //     this.clientRequestsDirector(output.director);
        // }

        // Request Claim
        // if (prevProps.output.claim !== output.claim && output.claim != null) {
        //     //  debugger;
        //     this.clientRequestsClaim(output.claim);
        // }

        // Claim Response
        // if (prevProps.output.approveClaim !== output.approveClaim && output.approveClaim) {
        //     if (output.approveClaim !== undefined) {
        //         this.clientResponseClaim(output.approveClaim);
        //     }
        //     output_reset();
        // }

        // Request Undo
        // if (prevProps.output.undo !== output.undo && output.undo) {
        //     if (output.undo !== undefined) {
        //         this.clientRequestsUndo();
        //     }
        //     output_reset();
        // }

        // Repond with YES Undo
        // if (prevProps.output.approveUndo !== output.approveUndo && output.approveUndo) {
        //     if (output.approveUndo !== undefined) {
        //         this.clientResponseUndo(output.approveUndo);
        //     }
        //     output_reset();
        // }

        // if (prevProps.output.changePlay !== output.changePlay && output.changePlay === 'startPlay') {
        //     this.clientRequestsStartPlay();
        //     output_reset();
        // }

        // if (prevProps.output.changePlay !== output.changePlay && output.changePlay === 'stopPlay') {
        //     this.clientRequestsStopPlay();
        //     output_reset();
        // }

        // if (prevProps.output.changePlay !== output.changePlay && output.changePlay === 'replay') {
        //     this.clientRequestsReplay();
        //     output_reset();
        // }

        // if (prevProps.output.prevNext !== output.prevNext && output.prevNext === 'next') {
        //     this.clientRequestsNextBoard();
        //     output_reset();
        // }

        // if (prevProps.output.prevNext !== output.prevNext && output.prevNext === 'prev') {
        //     this.clientRequestsPreviousBoard();
        //     output_reset();
        // }

        // if (!prevProps.output.pollVote && output.pollVote) {
        //     this.sendPollResponse(output.pollVote.pollId, output.pollVote.index);
        //     output_reset();
        // }

        // SET ACTIVE SEAT
        // if (prevState.currentPlayerOnServer !== currentPlayerOnServer) {
        //     this.props.setIsHighlighted(convertIntToBridgePosition(currentPlayerOnServer), true);
        //     if (
        //         this.sfs.mySelf.getPlayerId(this.sfs.lastJoinedRoom) <= 0 &&
        //         visibility !== 31 &&
        //         this.sfs.mySelf.containsVariable('kbd')
        //     ) {
        //         const seatid: number = this.sfs.mySelf.getVariable('kbd').value;
        //         if (seatid === 4) {
        //             this.props.setMyBridgePosition(convertIntToBridgePosition(currentPlayerOnServer));
        //         }
        //     }
        // }
        this.props.output_reset();
    }

    public render() {
        return null;
    }

    protected configureSFS() {
        //get URL parameters, most likely will move to th
        const urlHasParams = window.location.href.indexOf('?') !== -1;
        const urlParamsString = urlHasParams ? window.location.href.substring(window.location.href.indexOf('?') + 1) : '';
        const searchParams = new URLSearchParams(urlParamsString);
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.urlParams = [...searchParams.entries()].reduce<AppState['urlParams']>((cumulated, current) => {
                return {...cumulated, [current[0]]: current[1]};
            }, {});
        if (this.state.urlParams) {this.props.app_setUrlParams(this.state.urlParams);}
        this.config.host = this.state.urlParams?.['sfshost'] ? this.state.urlParams?.['sfshost'] + '.emabridge.com' : 'sfs.emabridge.com';
        this.config.zone = this.state.urlParams?.['service'] ? this.state.urlParams?.['service'] : 'BetterBridgeClub';
        this.config.port = 8443;
        return;
    }

    protected addSFSListeners() {
        // Add Event Listeners
        this.sfs.addEventListener(SFSEvent.SOCKET_ERROR, this.onSocketError, this);
        this.sfs.addEventListener(SFSEvent.CONNECTION, this.onConnection, this);
        this.sfs.addEventListener(SFSEvent.CONNECTION_LOST, this.onConnectionLost, this);
        this.sfs.addEventListener(SFSEvent.ROOM_JOIN, this.onRoomJoined, this);
        this.sfs.addEventListener(SFSEvent.ROOM_JOIN_ERROR, this.onRoomJoinError, this);
        this.sfs.addEventListener(SFSEvent.ROOM_VARIABLES_UPDATE, this.onRoomVarsUpdate, this);
        this.sfs.addEventListener(SFSEvent.USER_ENTER_ROOM, this.onUserEnterRoom, this);
        this.sfs.addEventListener(SFSEvent.USER_EXIT_ROOM, this.onUserExitRoom, this);
        this.sfs.addEventListener(SFSEvent.USER_VARIABLES_UPDATE, this.onUserVarsUpdate, this);
        this.sfs.addEventListener(SFSEvent.SPECTATOR_TO_PLAYER, this.onSpectatorToPlayerSwitch, this);
        this.sfs.addEventListener(SFSEvent.SPECTATOR_TO_PLAYER_ERROR, this.onSpectatorToPlayerSwitchError, this);
        this.sfs.addEventListener(SFSEvent.PLAYER_TO_SPECTATOR, this.onPlayerToSpectatorSwitch, this);
        this.sfs.addEventListener(SFSEvent.PLAYER_TO_SPECTATOR_ERROR, this.onPlayerToSpectatorSwitchError, this);
        this.sfs.addEventListener(SFSEvent.EXTENSION_RESPONSE, this.onExtensionResponse, this);
        this.sfs.addEventListener(SFSEvent.OBJECT_MESSAGE, this.onObjectMessage, this);
        this.sfs.addEventListener(SFSEvent.ADMIN_MESSAGE, this.onAdminMessage, this);
        this.sfs.addEventListener(SFSEvent.MODERATOR_MESSAGE, this.onModeratorMessage, this);
        this.sfs.addEventListener(SFSEvent.PUBLIC_MESSAGE, this.onPublicMessage, this);
        this.sfs.addEventListener(SFSEvent.PRIVATE_MESSAGE, this.onPrivateMessage, this);
        this.sfs.addEventListener(SFSEvent.PING_PONG, this.onPING_PONG, this);
        this.sfs.addEventListener(SFSEvent.LOGOUT, this.onLogout, this);
    }

    public onConnection = (evtParams: { success: boolean }) => {
        if (evtParams.success) {
              console.log('Connected to SmartFoxServer 2X!', evtParams);
            this.clientRequestsLogin();
        } else {
            console.log('Connection failed. Is the server running at all?', evtParams);
        }
    };

    protected onSocketError = ({ errorMessage }: { errorMessage: string }) => {
        parent.postMessage('reloaddash', '*');
        if (this.sfs == null || this.sfs.isConnected) {
            return;
        }
        const { app } = this.props;
        // this.props.setJitsi({});
        // TODO: Michael : show Modal, we should have pop up message with a possibility of some buttons.
        // this.props.addModal(
        //     getSocketErrorConfig({
        //         cancel: () => {
        //             window.location.href =
        //                 app.urlParams['sfshost'].toLowerCase() === 'local'
        //                     ? 'https://local.onlinebridge.club/index.php'
        //                     : 'https://thesharkbridgecompany.com/blogs/students-manual/how-to-practice-with-robots-or-fellow-students-with-shark-bridge-app';
        //         },
        //         retry: () => {
        //             this.sfs = undefined;
        //             this.logout();
        //         },
        //     }),
        // );
        this.props.app_setConnection({
            established: false,
            requested: false,
        });

        this.setState({
            processingLogin: false,
        });
    };

    protected onConnectionLost = ({ reason }: { reason: string }) => {
        console.log('A disconnection occurred due to : ' + reason);
        const { app } = this.props;
        if (!this.state.processingLogin && this.sfs !== undefined) {
            parent.postMessage('reloaddash', '*');
            if (reason !== 'manual') {
                //Show modal mesage to reconnect
            } else {
                //Close this iFrame ( can iFrame close it self? )
            }
        }
    };

    protected clientRequestsLogin = () => {
        const { app } = this.props;

        if (app.urlParams['jwe'] === undefined) {
            // return;
        }
        console.log('Login to SmartFoxServer 2X!', this.sfs.mySelf);
        if (this.sfs.mySelf !== null) {
            return;
        }
        this.props.app_setConnection({
            established: true,
            requested: true,
        });

        //const call: Call = strToCall("7c");
        //this.props.table_setAuction(mockedAuction);

        const sfsObj = new SFSObject();
        sfsObj.putInt(SFSVAR.PROTOCOL_VERSION, 50);
        sfsObj.putUtfString(SFSVAR.INTERFACE, 'betterb');
        sfsObj.putUtfString('jwe', app.urlParams['jwe']);
        sfsObj.putUtfString('stuid', app.uuid || '');

        this.sfs.addEventListener(SFSEvent.LOGIN, this.onLogin, this);
        this.sfs.addEventListener(SFSEvent.LOGIN_ERROR, this.onLoginError, this);
        const loginRequest = new LoginRequest(app.uuid || '', app.apptoken, sfsObj, this.config.zone);
        this.sfs.send(loginRequest);
    };

    protected clientRequestsLogout = () => {
        if (this.consoleOut) {
            console.log('Logout requested');
        }
        this.sfs.send(new LogoutRequest());
    };

    protected onLogin = ({ user, data }: { user: SFSUser; data: SFSObject }) => {
        this.props.app_setIsLoggedIn(true);
        this.setState({
            processingLogin: false,
        });
        if (this.consoleOut) {
            console.log(`Login success: ${user} - ${data.getDump()}`);
        }
        const monitor_interval: number = data.getInt(SFSVAR.SFS_LAG_MONITOR);
        //this.sfs.enableLagMonitor(true, 10, 1);
        //if (user.containsVariable("pn")) this.props.app.user = user.getVariable("pn").value;
        //else if (user.containsVariable("nn")) this.props.app.user = user.getVariable("nn").value;
        // this.props.setPlayerNames({
        //     [BridgePosition.north]: 'Empty Seat',
        //     [BridgePosition.east]: 'Empty Seat',
        //     [BridgePosition.south]: 'Empty Seat',
        //     [BridgePosition.west]: 'Empty Seat',
        // });
    };

     protected onLoginError = ({ errorMessage, errorCode }: { errorMessage: string; errorCode: number }) => {
       return;
    };

    protected onLogout = () => {
        this.setState({
            processingLogin: false,
        });
        this.sfs.disconnect();
        //  this.sfs = undefined;
        // TODO: Milen Unclear what would be the logic here
        this.props.app_reset();
        parent.postMessage('reloaddash', '*');
        // this.props.game_reset();
    };

    protected onRoomJoined = ({ room }: { room: SFSRoom }) => {
        //console.log('Room joined successfully: ',room);
        const { app } = this.props;
        const playerID: number = this.sfs.mySelf.getPlayerId(room);
        switch (room.isGame) {
            case false: {
                this.setState({
                    lobbyRoom: room,
                    isSharkZone: this.config.zone === 'OnlineBridgeClub',
                });
                break;
            }
            case true: {
                this.setState({
                    isMiniBridge: room.containsVariable(SFSVAR.SFSGAME_MINIBRIDGE)
                        ? (room.getVariable(SFSVAR.SFSGAME_MINIBRIDGE).value as boolean)
                        : false,
                });
                this.sendRoomCommand(SFSVAR.CMD_INTERFACE_READY, new SFSObject());
                break;
            }
        }
    };

    protected onRoomJoinError = ({ errorMessage, errorCode }: { errorMessage: string; errorCode: number }) => {
        // console.log('Room joining failed: ' + errorMessage);
        //TODO: Close iFrame or simply provide go to URL parameter.
    };


    protected onUserEnterRoom = ({ room, user }: { room: SFSRoom; user: SFSUser }) => {
        //console.log('onUserEnterRoom: ', room , user);
    };

    //IMPORTANT: if the user that left is ME, then move out of table to lobby, for now probably just a simple note or somethign.
    /**/
    protected onUserExitRoom = ({ room, user }: { room: SFSRoom; user: SFSUser }) => {
        //console.log('onUserExitRoom: ', room , user);
    };

    //This is like the onRoomJoin, but the player was actually kibitzing an is now seated at the table
    protected onSpectatorToPlayerSwitch = ({ room, user, playerID }: { room: SFSRoom; user: SFSUser; playerID: number }) => {
        //console.log('onSpectatorToPlayerSwitch: ', room , user, playerID);
    };

    protected onSpectatorToPlayerSwitchError = ({ errorMessage, errorCode }: { errorMessage: string; errorCode: number }) => {
        //console.log('onSpectatorToPlayerSwitchError: ', errorMessage , errorCode);
    };

    //Unseat the player, it turns in Kibitz. The important action here is to adjust hand visibility based on current rules for kibitzers
    protected onPlayerToSpectatorSwitch = ({ room, user }: { room: SFSRoom; user: SFSUser }) => {
        //console.log('onPlayerToSpectatorSwitch: ', room, user);
    };

    protected onPlayerToSpectatorSwitchError = ({ errorMessage, errorCode }: { errorMessage: string; errorCode: number }) => {
        //console.log('onPlayerToSpectatorSwitchError: ', errorMessage, errorCode);
    };

    protected onRoomVarsUpdate = ({ room, changedVars }: { room: SFSRoom; changedVars: string[] }) => {
        // console.log(`Room Var Update: `, changedVars);
        if (!room.isGame) {
            return;
        }
        if (room.containsVariable(SFSVAR.SFSGAME_NOAUCTION) && changedVars.includes(SFSVAR.SFSGAME_NOAUCTION)) {
            //this.props.showAuctionBox(!room.getVariable(SFSVAR.SFSGAME_NOAUCTION).value);
        }

        if (room.containsVariable(SFSVAR.SFSGAME_NOLASTTRICK) && changedVars.includes(SFSVAR.SFSGAME_NOLASTTRICK)) {
            //this.props.showClosedTricks(!room.getVariable(SFSVAR.SFSGAME_NOLASTTRICK).value);
        }

        if (room.containsVariable(SFSVAR.SFSGAME_ROOM_DISPLAY) && changedVars.includes(SFSVAR.SFSGAME_ROOM_DISPLAY)) {
            //this.props.setTableId(' ' + room.getVariable(SFSVAR.SFSGAME_ROOM_DISPLAY).value + ' ');
        }

        if (changedVars.includes(SFSVAR.SFSGAME_VIDEO_CLIP)) {
            //TODO: Show Video Screen this could be useful to load some video play like Vimeo or You tube
        }
    };

    protected onUserVarsUpdate = ({ user, changedVars }: { user: SFSUser; changedVars: string[] }) => {
        if (user.isItMe) {
            return;
        }
    };

    protected onPING_PONG = ({ lagValue }: { lagValue: number }) => {
        //console.log(`PING PONG: ${lagValue}`);
        if (!this.sfs.isConnected) {
            return;
        }
        //const diff: number = this.state.lag - lagValue;
        //console.log(`PING PONG DIFF: ${diff}`);
        //this.sfs.send(new ExtensionRequest(SFSVAR.EXTENSION_LAG));
    };

    protected onExtensionResponse = ({ cmd, params }: { cmd: string; params: SFSObject }) => {
        //console.log('Object Command: ' + cmd + ' object : ' + params.getDump());
        if (cmd === CMD.CMD_GAME_LOGIC) {
            // We expect atleast one INT value in the params: EXTENSION_MID with values one of netMID
            //console.log('The custom Extension is: ' + params.getDump());
            this.processObject(params);
        } else if (cmd === SFSVAR.CMD_CONTROL_ACTION) {
            this.processObject(params);
        } else if (cmd === SFSVAR.SFSGAME_CARD_HIGHLIGHT) {
            //TO Do highlight cards
        } else if (cmd === SFSVAR.SFSGAME_VIDEO_CLIP) {
            //TO DO Show Video Screen
        } else if (cmd === SFSVAR.SFSGAME_FRAME_URL) {
            //TO DO Show frame URL, for play cards IO, or somethign else
        } else if (cmd === SFS_EXT.EXT_COLUMN_INDEX) {
            this.setIndexMenu(params);
            this.clientRequestsSettingsUpdate();
        }
    };

    //This one can have data, regarding request to unblock a user, we will not deal with it now.
    protected onAdminMessage = ({ message, sender, data }: { message: any; sender: SFSUser; data: SFSObject }) => {
        return;
    };

    protected onModeratorMessage = ({ message, sender }: { message: string; sender: SFSUser }) => {
        return;
    };
    //On a special case where there is data sent back,
    protected onPublicMessage = ({ message, sender, data }: { message: string; sender: SFSUser; data: SFSObject }) => {
       return;
    };

    protected onPrivateMessage = ({ message, sender, data }: { message: string; sender: SFSUser; data: SFSObject }) => {
        return;
    };

    protected onObjectMessage = ({ message, sender }: { message: SFSObject; sender: SFSUser }) => {
        if (message.containsKey(SFSVAR.EXTENSION_MID)) {
            //Pass the same SFS_OBJECT_MESSAGE processor. Server will often send object as a result of game logic , rather than in response
            // to an Extention request.
            // console.log('The object command is: ' + message.getInt(SFSVAR.EXTENSION_MID));
            this.processObject(message);
        }
    };

    protected processObject = (cmdObj: SFSObject) => {
        const mid: number = cmdObj.getInt(SFSVAR.EXTENSION_MID);
        // console.log("Process Object : ",SFSVAR.netMID[mid] , cmdObj.getDump());
        switch (mid) {
            case SFSVAR.netMID.MID_NEW_DEAL: {
                //console.log("Process Object : ", cmdObj.getDump());
                this.process_MID_CURRENT_DEAL(cmdObj);
                this.sendRoomCommand(SFSVAR.CMD_TABLE_READY, new SFSObject());
                break;
            }
            case SFSVAR.netMID.MID_CURRENT_DEAL: {
                //console.log("Process Object : ", cmdObj.getDump());
                this.process_MID_CURRENT_DEAL(cmdObj);
                break;
            }

            case SFSVAR.netMID.MID_CHANGE_HAND_VISIBILITY: {
                //   this.process_MID_CHANGE_HAND_VISIBILITY(cmdObj);
                return;
            }

            case SFSVAR.netMID.MID_TIME_BID: {
                // 5
                this.process_MID_TIME_TO_BID(cmdObj);
                return;
            }
            case SFSVAR.netMID.MID_MADE_BID: {
                // 6
                this.process_MID_MADE_BID(cmdObj);
                return;
            }
            case SFSVAR.netMID.MID_CONTRACT: {
                // 7
                this.process_MID_CONTRACT(cmdObj);
                return;
            }
            case SFSVAR.netMID.MID_TIME_PLAY: {
                // 8
                this.process_MID_TIME_TO_PLAY(cmdObj);
                return;
            }
            case SFSVAR.netMID.MID_MADE_PLAY: {
                // 9
                this.process_MID_MADE_PLAY(cmdObj);
                return;
            }
            case SFSVAR.netMID.MID_TIME_CLOSE_TRICK: {
                // 10
                this.process_MID_TIME_CLOSE_TRICK(cmdObj);
                return;
            }
            case SFSVAR.netMID.MID_TIME_SCORE: {
                // 11
                this.process_MID_TIME_TO_SCORE(cmdObj);
                return;
            }
            case SFSVAR.netMID.MID_NEXT_DEAL: {
                //12
                // Not used right now.
                return;
            }
            case SFSVAR.netMID.MID_REPLAY_DEAL: {
                //13
                // Not used right now.
                return;
            }
            case SFSVAR.netMID.MID_CLAIM_REQUEST: {
                //14
                // Not used right now.
                return;
            }

            case SFSVAR.netMID.MID_CLAIM_RESPONSE: {
                //15
                // Not used right now.
                //console.log('MID_CLAIM_RESPONSE: ', cmdObj);
                if (cmdObj.containsKey('message')) {
                    this.setMessage('center', cmdObj.getSFSObject('message'));
                }
                //    this.props.removeCurrentModal();
                //  this.props.addModal(getAlert([<div>Your claim is being processed!</div>]));
                return;
            }

            case SFSVAR.netMID.MID_UNDO_RESPONSE: {
                //15
                // Not used right now.
                //   this.props.removeCurrentModal();
                return;
            }

            case SFSVAR.netMID.MID_UNDO_NOTIFICATION: {
                //    this.process_MID_UNDO_NOTIFICATION(cmdObj);
                return;
            }

            case SFSVAR.netMID.MID_CLAIM_NOTIFICATION: {
                //   this.process_MID_CLAIM_NOTIFICATION(cmdObj);
                return;
            }

            case SFSVAR.netMID.MID_CONTROL_ACTION: {
                //   this.process_MID_ACTION_CONTROL(cmdObj);
                return;
            }

            case SFSVAR.netMID.MID_SET_HANDS: {
                //  this.process_MID_SET_HANDS(cmdObj);
                return;
            }
            case SFSVAR.netMID.MID_SHOW_WELCOME: {
                this.process_POP_WELCOME_MESSAGE();
                return;
            }
        }
    };

    protected process_MID_CURRENT_DEAL(cmdObj: SFSObject) {
        //console.log('---------------------------process_MID_CURRENT_DEAL');
        //console.log('Lobby Variables: \n ' + this.state.myLobby);
        //console.log('The deal is: \n ' + pbnStr);
        //console.log('The deal is: \n ' + cmdObj.getDump());
        const gameState: SFSObject = cmdObj.getSFSObject('tablestate');
        this.setState({
            currentPlayer: undefined,
        });
        //clear table data.
        this.props.table_reset();

        this.props.table_setForceShowAllCards(this.forceShowAllCards);

        this.props.table_setIndexButtons(this.indexButtons);

        const showdummy = gameState.containsKey('showdummy') && gameState.getBool('showdummy');
        const showBiddingbox = gameState.containsKey('biddingbox');

        if (cmdObj.containsKey('mainmessage')) {
            //console.log("Main Message : " , cmdObj.getUtfString('mainmessage'));
            //.replace(new RegExp("quiz:", 'g'), "quiz://")
            this.props.table_setWalkTrough({ content: cmdObj.getUtfString('mainmessage') });
            if (showdummy) {
                this.props.table_setCardWidthMultiplier(1.35);
            } else {
                this.props.table_setCardWidthMultiplier(1.35);
            }
        } else {
            this.props.table_setWalkTrough(undefined);
            if (!showBiddingbox) {
                this.props.table_setCardWidthMultiplier(1.02);
            } else {
                this.props.table_setCardWidthMultiplier(1.095);
            }
        }

        //Establish the menu buttons for this screen
        this.setControlButtons(cmdObj.containsKey('buttons') ? cmdObj.getSFSObject('buttons') : undefined);

        if (gameState.containsKey('tablehtml')) {
            //console.log("Table HTML : " , gameState.getUtfString('tablehtml'));
            this.props.table_setBridgeTableHTML(gameState.getUtfString('tablehtml'));
            return;
        }

        this.props.table_setBridgeTableHTML(undefined);
        this.props.table_setBridgeTableVisibility(true);

        const tableState: number = gameState.getInt('state');

        //reset all cards back to DECK.
        this.props.cards_reset();
        this.props.table_setTopBridgePosition(BridgePosition.north);
        this.props.table_setPrimaryLabelToBridgePosition();

        //set the suit order
        //console.log("Highlight : ", oblState.getUtfStringArray("highlights"));
        //console.log("Highlighted String : ", oblState.getUtfString("highlightStr"));
        this.props.table_setSuitOrder(intToSuitOrder(gameState.getInt('suitorder')));

        const contract: SFSObject = gameState.getSFSObject('contract'); //declarer
        const declarer: number = contract.getInt('declarer');
        const passed: boolean = contract.getBool('passed');

        const dealer: BridgePosition = convertIntToBridgePosition(gameState.getInt('dealer'));

        this.props.table_setDealer(dealer);

        if (declarer !== -1 && !passed) {
            //We have contract set declarer
            this.props.table_setDeclarer(convertIntToBridgePosition(declarer));
        }

        this.setTableInfo(gameState);
        /*
        if (showdummy) {
            console.log("Dummy is ", convertIntToBridgePosition(declarer ^ 2));
           this.props.table_setDummy(convertIntToBridgePosition(declarer ^ 2));
        }
        */
        //update hand trays and card holdings
        this.setHandsFromState(gameState, cmdObj.containsKey('mainmessage'));

        //update auction
        this.setAuctionFromState(gameState, dealer);

        if (this.state.isMiniBridge) {
            this.props.table_setShowAuctionLine(false);
        } else {
            this.props.table_setShowAuctionLine(!gameState.getBool(SFSVAR.SFSGAME_NOAUCTION));
        }

        //update bidding ladder
        this.setBiddingLadderFromState(gameState);

        //Deal with the line of play
        const playArray: SFSArray | undefined = gameState.containsKey('bbplay')
            ? gameState.getSFSArray('bbplay')
            : gameState.containsKey('play')
            ? gameState.getSFSArray('play')
            : undefined;
        if (playArray !== undefined && playArray.size() > 0) {
            //console.log("Play state : " , playArray);
            this.setTrickHistory(playArray);
        } else {
            // this.setCardsToTrickFromHand(gameState);
        }

        this.props.table_setTrickCounts({ ns: gameState.getInt('nstr'), ew: gameState.getInt('ewtr') });

        this.props.table_setShowTrickCounter(gameState.getBool('showtc'));

        if (gameState.containsKey('centermessage')) {
            this.setMessage('center', gameState.getSFSObject('centermessage'));
        }

        if (gameState.containsKey('bleftmessage')) {
            this.setMessage('left', gameState.getSFSObject('bleftmessage'));
        }

        if (gameState.containsKey('brightmessage')) {
            this.setMessage('right', gameState.getSFSObject('brightmessage'));
        }
    }

    protected process_MID_TIME_TO_BID(cmdObj: SFSObject) {
        //console.log("this is mini bridge ", this.state.isMiniBridge);
        //console.log("Bidding Ladder", cmdObj );
        if (cmdObj.containsKey('highbid')) {
            return;
        }
        if (this.state.isMiniBridge) {
            this.setSimpleMessage(
                'right',
                'This is a mini bridge game!</br></br>Select a contract from the bidding ladder on the right.',
                undefined,
            );
            return;
        }
        if (this.state.lobbyRoom) {
            this.setSimpleMessage('right', 'Select a call from the Bidding Ladder on the right.', undefined);
        }
        const stake: number = cmdObj.getInt('stake');
        const validCall: number = cmdObj.containsKey('highbid')
            ? cmdObj.getInt('highbid')
            : cmdObj.containsKey('validbid')
            ? cmdObj.getInt('validbid')
            : 0;
        //console.log("Valid Call", validCall );
        const minCall: Call = validCall === 0 ? ('1c' as Call) : convertIntToCall(validCall);
        this.props.table_updateBiddingLadder({
            showArrow: true,
            firstVisibleCall: minCall,
            minimalValidCall: minCall,
            isActive: true,
            isVisible: true,
            showBonusIndicator: true,
            bonusIndicators: [BonusIndicator.j, BonusIndicator.m, BonusIndicator.s, BonusIndicator.g],
            stake:
                stake === 4
                    ? {
                          backgroundColor: undefined,
                          call: 'rdbl',
                          disabled: false,
                          invisible: false,
                      }
                    : {
                          backgroundColor: undefined,
                          call: 'xdbl',
                          disabled: stake !== 2,
                          invisible: false,
                      },
        });
    }

    protected process_MID_MADE_BID(cmdObj: SFSObject) {
        /*this.props.setActiveSeat(undefined);
        this.props.setSoundPlaying(undefined);
        this.props.setEnabledBiddingBox(false);
        const call: number = cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD);
        const nextPlayerBridgePosition: number = cmdObj.getInt(SFSVAR.SFSGAME_CURENT_PLAYER);
        this.setState({
            currentPlayerOnServer: nextPlayerBridgePosition,
            isDummy: false
        });
        this.props.setIsHighlighted(convertIntToBridgePosition(nextPlayerBridgePosition), true);

        if (
            this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_MINIBRIDGE) &&
            this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_MINIBRIDGE).value
        ) {
            return;
        }

        let bid: IBid = convertIntToBid(call);
        if (cmdObj.containsKey(SFSVAR.SFSGAME_MID_ALERT)) {
            const { game } = this.props;
            const playerBridgePosition: number = cmdObj.getInt(SFSVAR.SFSGAME_PLAYER);
            // TODO Check ts-ignore errors
            // @ts-ignore
            const mybridgeposition: BridgePosition = getMySeatData(game.seatData).bridgePosition;
            const bidderParnerBridgePossition: BridgePosition = convertIntToBridgePosition(playerBridgePosition ^ 2);
            if (bidderParnerBridgePossition !== mybridgeposition) {
                bid.alertMessage = cmdObj.getUtfString(SFSVAR.SFSGAME_MID_ALERT);
                this.props.addChatEntry({ timestamp: moment.utc().format(), sender: 'Alert!', message: bid.alertMessage });
            }
        }

        this.props.appendAuction(bid);
        if (nextPlayerBridgePosition !== -1) {
        }*/
        console.log('A bid was made ', cmdObj);
    }

    protected process_MID_CONTRACT(cmdObj: SFSObject) {
        this.props.table_setShowAuctionLine(false);
        this.props.table_updateBiddingLadder({
            isVisible: false,
        });
        this.sendRoomCommand(SFSVAR.CMD_SEND_ME_DEAL, new SFSObject());
        /*if (this.state.isSharkZone) {

        }*/
    }

    protected process_MID_TIME_TO_PLAY(cmdObj: SFSObject) {
        if (!cmdObj.containsKey('closetrick') || !cmdObj.getBool('closetrick')) {
            this.props.table_shouldCloseTrick(false);
        }
        const currentPlayer: number = cmdObj.getInt(SFSVAR.SFSGAME_CURENT_PLAYER);
        const values1 = Object.values(BridgePosition);

        this.setState({
            currentPlayer: currentPlayer,
        });
        values1.forEach((value, index) => {
            // 👇️ S, M, L
            this.props.table_setSeatData({
                seatPosition: bridgePosToSeatPos(value),
                background: undefined,
            });
        });

        this.props.table_setSeatData({
            seatPosition: bridgePosToSeatPos(convertIntToBridgePosition(currentPlayer)),
            isInteractive: true,
            isVisible: true,
            background: '#fce303',
        });

        // console.log('process_MID_TIME_TO_PLAY', cmdObj.getDump());
    }

    protected process_MID_MADE_PLAY(cmdObj: SFSObject) {
        console.log('Made a play ', cmdObj);
        const cardid: CardId = strToCardId(cmdObj.getUtfString('o'));
        const cp: BridgePosition = convertIntToBridgePosition(cmdObj.getInt('cp'));

        this.setHandsFromState(cmdObj, false);

        //Should update dummy
        if (cmdObj.getBool('showdummy')) {
            this.props.table_setDummy(cp);
        }
        if (this.props.table.tricks.length === 0) {
            this.props.table_addTrick(emptyTrick);
        } else if (this.props.table.tricks[this.props.table.tricks.length - 1].cardIds.length === 4) {
            this.props.table_claimTrick(cp);
            this.props.table_addTrick(emptyTrick);
        }
        const cardUpdates: CardUpdateProps[] = [
            {
                bridgePosition: convertIntToBridgePosition(cmdObj.getInt('p')),
                cardPosition: CardPosition.TRICK,
                id: cardid,
                raised: false,
                highlighted: false,
                visible: true,
            },
        ];
        const positions = Object.values(BridgePosition);
        positions.forEach((value, index) => {
            this.props.table_setSeatData({
                seatPosition: bridgePosToSeatPos(value),
                background: value === cp ? '#fce303' : undefined,
            });
        });

        this.props.cards_setCards(cardUpdates);
        this.props.table_playCard(cardid);
        this.props.table_setTrickCounts({ ns: cmdObj.getInt('nstr'), ew: cmdObj.getInt('ewtr') });
        if (cmdObj.containsKey('buttons')) {
            this.setControlButtons(cmdObj.getSFSObject('buttons'));
        }

        if (cmdObj.getInt(SFSVAR.SFSGAME_GAME_STATE) === 18) {
            this.props.table_shouldCloseTrick(true);
        }
    }

    protected process_MID_TIME_CLOSE_TRICK(cmdObj: SFSObject) {
        //console.log('process_MID_TIME_CLOSE_TRICK', cmdObj.getDump());
        const cp: BridgePosition = convertIntToBridgePosition(cmdObj.getInt('ctw'));
        if (this.props.table.tricks[this.props.table.tricks.length - 1].cardIds.length === 4) {
            this.props.table_claimTrick(cp);
            this.props.table_addTrick(emptyTrick);
        }
        this.props.table_setTrickCounts({ ns: cmdObj.getInt('nstr'), ew: cmdObj.getInt('ewtr') });
    }

    protected process_MID_TIME_TO_SCORE(cmdObj: SFSObject) {
        if (!this.state.isSharkZone) {
            return;
        }
        this.setControlButtons(cmdObj.containsKey('buttons') ? cmdObj.getSFSObject('buttons') : undefined);
        this.setHandsFromState(cmdObj, false);

        const oblState: SFSObject | undefined = cmdObj.containsKey('oplay') ? cmdObj.getSFSObject('oplay') : undefined;
        const nsTricks: number = cmdObj.containsKey('nstr')
            ? cmdObj.getInt('nstr')
            : oblState?.containsKey('nstr')
            ? oblState.getInt('nstr')
            : 0;
        const ewTricks: number = cmdObj.containsKey('ewtr')
            ? cmdObj.getInt('ewtr')
            : oblState?.containsKey('ewtr')
            ? oblState.getInt('ewtr')
            : 0;

        this.props.table_setTrickCounts({ ns: nsTricks, ew: ewTricks });
        const score: number = cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD);

        if (!this.state.isMiniBridge) {
            this.props.table_setShowAuctionLine(true);
        }

        /* if (cmdObj.containsKey('stats')) {
            const boardStats: BoardStats[] = JSON.parse(new TextDecoder('utf-8').decode(cmdObj.getByteArray('stats')));
            this.props.setBoardStats(boardStats);
            if (cmdObj.containsKey('mystat')) {
                const myStatsKey: BoardStatKeys = JSON.parse(new TextDecoder('utf-8').decode(cmdObj.getByteArray('mystat')));
                this.props.setMyBoardStatKeys(myStatsKey);
            } else {
                this.props.setMyBoardStatKeys({} as BoardStatKeys);
            }
        } else {
            this.props.setBoardStats([]);
            this.props.setMyBoardStatKeys({} as BoardStatKeys);
        }*/
    }

    protected process_POP_WELCOME_MESSAGE() {
        if (this.state.lobbyRoom?.containsVariable(SFSVAR.SFSGAME_ROOM_WELCOME_MESSAGE)) {
            this.setSimpleMessage(
                'center',
                this.state.lobbyRoom?.getVariable(SFSVAR.SFSGAME_ROOM_WELCOME_MESSAGE).value as string,
                'OK',
            );
        }
    }

    protected setHandsFromState(state: SFSObject, visibleWalkthru: boolean) {
        //console.log("Hands Data: ", state);
        const visibility: number = state.getByte(SFSVAR.SFSGAME_MID_HANDPLAYER_VISIBILITY);

        Object.values(BridgePosition).forEach((value, index) => {
            // 👇️ S, M, L
            this.props.table_setSeatData({
                seatPosition: bridgePosToSeatPos(value),
                background: undefined,
            });
        });

        Object.values(SFS_HAND_PROPS).forEach((value, index) => {
            this.setHandFromState(getSFSHandState(value, state, visibility, visibleWalkthru));
        });

        if (state.containsKey(SFS_HAND_PROPS.center)) {
            this.setHandFromState(getSFSHandState(SFS_HAND_PROPS.center, state, visibility, visibleWalkthru));
            this.props.table_setCenterSeat(true);
        } else {
            this.props.table_setCenterSeat(false);
        }
    }

    protected setHandFromState(
        sfsHandState: SFS_HAND_STATE | undefined
    ) {
        if (sfsHandState === undefined) {return;}
        //console.log("Hand Highlight : ", state.containsKey('highlight') ? fixColor(state.getUtfString('highlight')) : undefined);
        //console.log("Hand Object : " , state)
        const isdummy: boolean = sfsHandState.state.getBool('dummy');
        const isvisible: boolean = sfsHandState.state.getBool('faceup');
        const isactive: boolean = sfsHandState.state.getBool('isactive');
        const isbottomhand: boolean = sfsHandState.seatPosition === SeatPosition.bottom;
        let handSize: 's' | 'm' | 'l' = strToSize(sfsHandState.state.getUtfString('size'));

        if (isdummy) {
            this.props.table_setDummy(sfsHandState.bridgePosition);
        }

        const dynamicTray = false;
        if (isbottomhand && isactive && handSize === 'l') {
            //    dynamicTray = true;
        }

        if (isbottomhand && handSize === 'm') {
            handSize = 'l';
        }

        let sizeModifier = 0;
        if (sfsHandState.visibleWalkthru) {
            //sizeModifier += 0.15;
            if (!isdummy && handSize === 'l') {
                sizeModifier += 0.5;
            }
        } else {
            if (!isdummy && handSize === 'l') {
                sizeModifier += 0.445;
            } else if (handSize === 's') {
                sizeModifier -= 0.15;
            }
        }

        const stateStr: string = sfsHandState.state.getUtfString('highlighstr');
        const hand: string[] = sfsHandState.state.getUtfStringArray('handstr') as string[];

        this.props.table_setSeatData(seatDataFromSFSObject(sfsHandState,sizeModifier,dynamicTray,hand,isdummy,isvisible,handSize));
        if (hand.length > 0 && hand[0].length > 0) {
            this.props.cards_setCards(cardSetFromString(hand, sfsHandState, stateStr ));
        }
    }

    protected setAuctionFromState(state: SFSObject, dealer: BridgePosition) {
        //console.log("Play state : " , state.getDump());
        if (!state.containsKey('auction') && !state.containsKey('bbauction')) {
            return;
        }

        const bridgePositionArray =
            dealer === BridgePosition.north
                ? [BridgePosition.north, BridgePosition.east, BridgePosition.south, BridgePosition.west]
                : dealer === BridgePosition.east
                ? [BridgePosition.east, BridgePosition.south, BridgePosition.west, BridgePosition.north]
                : dealer === BridgePosition.south
                ? [BridgePosition.south, BridgePosition.west, BridgePosition.north, BridgePosition.east]
                : [BridgePosition.west, BridgePosition.north, BridgePosition.east, BridgePosition.south];

        const callArray: SFSArray = state.containsKey('bbauction')
            ? state.getSFSArray('bbauction')
            : state.getSFSArray('auction');
        const mockedAuction: Auction = [];
        //console.log("Auction : ", callArray.getDump());
        for (let i = 0; i < callArray.size(); i++) {
            const call: SFSObject = callArray.getSFSObject(i);
            mockedAuction.push({
                bridgePosition: bridgePositionArray[i % bridgePositionArray.length],
                call: strToCall(call.getUtfString('c')),
                backgroundColor: call.containsKey('bg') ? fixColor(call.getUtfString('bg')) : undefined,
                key: i,
            });
            // etc...
        }
        this.props.table_setAuction(mockedAuction);
    }

    protected setBiddingLadderFromState(state: SFSObject) {
        //console.log("Show Bidding box" , state.getSFSObject('biddingbox'));
        if (!state.containsKey('biddingbox')) {
            this.props.table_updateBiddingLadder({
                isVisible: false,
            });
            return;
        }
        const bl: SFSObject = state.getSFSObject('biddingbox');
        const stake: number = bl.getInt('stake');

        this.props.table_updateBiddingLadder({
            showArrow: bl.getBool('showarrow'),
            firstVisibleCall: strToCall(bl.getUtfString('mc')),
            minimalValidCall: strToCall(bl.getUtfString('mvc')),
            isActive: bl.getBool('active'),
            isVisible: true,
            showBonusIndicator: bl.getBool('bonus'),
            bonusIndicators: [BonusIndicator.j, BonusIndicator.m, BonusIndicator.s, BonusIndicator.g],
            stake:
                stake === 4
                    ? {
                          backgroundColor: undefined,
                          call: 'rdbl',
                          disabled: false,
                          invisible: false,
                      }
                    : {
                          backgroundColor: undefined,
                          call: 'xdbl',
                          disabled: stake !== 2,
                          invisible: false,
                      },
        });

        if (bl.containsKey('hcalls')) {
            const calls: SFSArray = bl.getSFSArray('hcalls');
            //    console.log("Bidding Ladder HCalls : ", calls);
            const callsArray: Auction = [];
            for (let i = 0; i < calls.size(); i++) {
                const call: SFSObject = calls.getSFSObject(i);
                callsArray.push({
                    call: strToCall(call.getUtfString('c')),
                    backgroundColor: call.containsKey('bg') ? fixColor(call.getUtfString('bg')) : undefined,
                    key: i,
                });
            }
            //      console.log("Bidding Ladder Result : ", callsArray);
            this.props.table_updateBiddingLadderBids(callsArray);
        }
    }

    protected setTrickHistory(tricks: SFSArray) {
        //console.log("Card array : " , tricks.getDump());
        const cardUpdates: CardUpdateProps[] = [];
        let winner: BridgePosition | undefined = undefined;
        for (let i = 0; i < tricks.size(); i++) {
            if (i === 0) {
                this.props.table_addTrick(emptyTrick);
            }
            const card: SFSObject = tricks.getSFSObject(i);
            // console.log("Card to load : " , card.getDump());
            cardUpdates.push({
                bridgePosition: convertIntToBridgePosition(card.getInt('p')),
                cardPosition: CardPosition.TRICK,
                id: strToCardId(card.getUtfString('c')),
                raised: false,
                highlighted: false,
                visible: true,
            });
            this.props.table_playCard(strToCardId(card.getUtfString('c')));
            if (card.getBool('w')) {
                winner = convertIntToBridgePosition(card.getInt('p'));
            }
            if (winner !== undefined && i > 0 && (i + 1) % 4 === 0) {
                //we are at the end of trick , close it
                //console.log("Closing trick : " , (i+1) % 4 , " : " , winner);
                this.props.table_claimTrick(winner);
                this.props.table_addTrick(emptyTrick);
                winner = undefined;
            }
        }
        this.props.cards_setCards(cardUpdates);
    }

    protected setCardsToTrickFromHand(state: SFSObject) {
        //
        const cardUpdates: CardUpdateProps[] = [];
        this.props.table_addTrick(emptyTrick);
        const bridgePositions = Object.values(BridgePosition);
        Object.values(SFS_HAND_PROPS).forEach((key, index) => {
            // 👇️ Small, Medium, Large
            if (index !== 4) {
                //console.log("Card from hand : " , Object.values(SFS_HAND_PROPS) , hands);
                if (state.containsKey(key)) {
                    const hand: SFSObject = state.getSFSObject(key);
                    const card: string | undefined = hand.containsKey('cc') ? hand.getUtfString('cc') : undefined;
                    //console.log("Card to trick : " , card," from hand: ", hands[index]);
                    if (card !== undefined && card.length === 2) {
                        this.props.table_playCard(strToCardId(card));
                        cardUpdates.push({
                            bridgePosition: bridgePositions[index],
                            cardPosition: CardPosition.TRICK,
                            id: strToCardId(card),
                            visible: true,
                        });
                    }
                }
            }
        });

        this.props.cards_setCards(cardUpdates);
    }

    /*
     public SFSObject getSFS(){
        SFSObject result = new SFSObject();
        result.putUtfString("m", message );
        if (title != null)  result.putUtfString("t", title );
        if (color != null)  result.putUtfString("c", color );
        if (button != null) result.putUtfString("ok", button );
        if (cancelButton != null) result.putUtfString("cancel", cancelButton );
        return result;
    }
     */

    protected setMessage(position: 'center' | 'left' | 'right', message: SFSObject) {
        const m: AuxiliaryMessage = {
            text: message.getUtfString('m'),
            title: message.containsKey('t') ? message.getUtfString('t') : undefined,
            titleColor: message.containsKey('c') ? message.getUtfString('c') : undefined,
            buttonOk: message.containsKey('ok') ? message.getUtfString('ok') : undefined,
            buttonCancel: message.containsKey('cancel') ? message.getUtfString('cancel') : undefined,
            buttonAuxiliary: message.containsKey('aux') ? message.getUtfString('aux') : undefined,
        };
        this.props.table_setAuxiliaryMessage({ position: position, message: m });
    }

    protected setSimpleMessage(position: 'center' | 'left' | 'right', message: string, okButton: string | undefined) {
        const m: AuxiliaryMessage = {
            text: message,
            title: undefined,
            titleColor: undefined,
            buttonOk: undefined,
            buttonCancel: okButton,
            buttonAuxiliary: undefined,
        };
        this.props.table_setAuxiliaryMessage({ position: position, message: m });
    }

    protected setControlButtons(cmdObj: SFSObject | undefined) {
        //TODO: adjust control buttons
        const footerButtons: TableState['footerButtons'] = [];
        const noButtons = cmdObj === undefined;
        //Add the ever present Dashboard
        footerButtons.push({
            label: noButtons || !cmdObj.containsKey('button0') ? 'Dashboard' : cmdObj.getUtfString('button0'),
            id: 'dashboard',
            value: true,
            highlighted: false,
        });
        // console.log("Control Buttons : " , footerButtons);
        for (let i = 1; i <= 9; i++) {
            const buttonIndex = `button${i}`;
            //         console.log("Control Buttons : " , buttonIndex);
            footerButtons.push(
                noButtons || !cmdObj.containsKey(buttonIndex)
                    ? undefined
                    : {
                          label: cmdObj.getSFSObject(buttonIndex).getUtfString('t'),
                          id: cmdObj.getSFSObject(buttonIndex).getUtfString('bid'),
                          value: cmdObj.getSFSObject(buttonIndex).getUtfString('a'),
                          highlighted: cmdObj.getSFSObject(buttonIndex).getBool('h'),
                      },
            );
        }
        //    console.log("Control Buttons : " , footerButtons);
        this.props.table_setFooterButtons(footerButtons);
    }

    protected setTableInfo(state: SFSObject) {
        const infoObj: SFSObject | undefined = state.containsKey('tbinfo') ? state.getSFSObject('tbinfo') : undefined;
        if (infoObj !== undefined) {
            const leftinfo: TableInfo[] = [];
            const rightinfo: TableInfo[] = [];
            leftinfo.push(tableInfoFromSFSObject(infoObj, 'dealer'));
            leftinfo.push(tableInfoFromSFSObject(infoObj, 'vuln'));
            leftinfo.push(tableInfoFromSFSObject(infoObj, 'deal'));
            leftinfo.push(infoObj.containsKey('dealInfo') ? infoObj.getUtfString('dealInfo') : '');

            rightinfo.push(tableInfoFromSFSObject(infoObj, 'cont'));
            rightinfo.push(tableInfoFromSFSObject(infoObj, 'decl'));
            rightinfo.push(tableInfoFromSFSObject(infoObj, 'obj'));

            this.props.table_setTableInfo({ left: leftinfo, right: rightinfo });
            return;
        }

        this.props.table_setTableInfo({ left: undefined, right: undefined });
    }

    protected setIndexMenu(params: SFSObject) {
        //TODO: We have SFSArray with the topic index.
        const buttons: SFSArray = params.getSFSArray(SFSVAR.SFSGAME_MID_LOAD);
        const index: TableState['indexButtons'] = [];
        for (let i = 0; i < buttons.size(); i++) {
            const button: SFSObject = buttons.getSFSObject(i);
            index.push({
                label: button.getUtfString('title'),
                id: 'indexButton',
                value: i,
                highlighted: false,
            });
        }
        this.indexButtons = index;
        //console.log("protected Buttons:" , this.indexButtons);

        this.props.table_setIndexButtons(this.indexButtons);

        if (params.containsKey('favurl')) {
            this.favurl = params.getUtfString('favurl');
        }
    }

    //Locally handled user actions

    protected toggleIndexMenu = () => {
        //  console.log('Toggle Index Menu');
        this.props.table_setWalkThroughIframe(undefined);
        this.props.table_updateTable({
            showIndexButtons: !this.props.table.showIndexButtons,
        });
    };

    //Navigation actions by user sent to server
    protected clientRequestsNextPoint = () => {
        this.props.table_setWalkThroughIframe(undefined);
        this.sendRoomCommand(SFS_NAV.CMD_NEXT_POINT, new SFSObject());
    };

    protected clientRequestsNextHand = () => {
        const sfsObj = new SFSObject();
        sfsObj.putBool(SFSVAR.SFSGAME_MID_LOAD, false);
        sfsObj.putInt(SFSVAR.EXTENSION_MID, SFSVAR.netMID.MID_CONTROL_ACTION);
        this.sendRoomCommand(SFSVAR.CMD_NEXT_DEAL, new SFSObject());
        this.sendRoomCommand(SFSVAR.CMD_CONTROL_ACTION, sfsObj);
    };

    protected clientRequestsPreviousPoint = () => {
        this.sendRoomCommand(SFS_NAV.CMD_PREV_POINT, new SFSObject());
    };

    protected clientRequestsTraining = () => {
        this.forceShowAllCards = false;
        this.sendRoomCommand(SFS_NAV.CMD_LOAD_TRAINING, new SFSObject());
    };

    protected clientRequestsAutoBid = () => {
        this.sendRoomCommand(SFS_NAV.CMD_AUTOBID, new SFSObject());
    };

    protected clientRequestsAnswer = () => {
        this.sendRoomCommand(SFS_NAV.CMD_ANSWER, new SFSObject());
    };

    protected clientRequestsUndo = () => {
        this.sendRoomCommand(SFS_NAV.CMD_UNDO, new SFSObject());
    };

    protected clientRequestsReplay = () => {
        this.sendRoomCommand(SFS_NAV.CMD_REPLAY, new SFSObject());
    };

    protected clientRequestsSkip = () => {
        this.props.table_setWalkThroughIframe(undefined);
        this.sendRoomCommand(SFS_NAV.CMD_NEXT_SCREEN, new SFSObject());
    };

    protected clientRequestsTopicAtIndex = (index: number) => {
        //console.log("Index Button clicked" , index);
        const sfsObj = new SFSObject();
        sfsObj.putInt(SFSVAR.SFSGAME_MID_LOAD, index);
        this.sendRoomCommand(SFS_NAV.CMD_LOAD_INDEX, sfsObj);
    };

    protected clientRequestToggleVisibility() {
        this.forceShowAllCards = !this.props.table.forceShowAllCards;
        this.props.table_setForceShowAllCards(!this.props.table.forceShowAllCards);
    }

    protected clientRequestsFav = (notes: string) => {
        // console.log('Add Fav Press', this.favurl);
        this.props.table_setWalkThroughIframe(this.props.table.walkThroughIframe ? undefined : this.favurl);
        //    window.postMessage('closefav', '*');
        /*    const sfsObj = new SFSObject();
        sfsObj.putUtfString(SFSVAR.SFSGAME_MID_LOAD, notes);
        this.sendRoomCommand(SFS_NAV.CMD_FAV, sfsObj);*/
    };

    protected clientRequestsHTMLLink = (clickEventData: ClickEventData) => {
        //parce the link and handle it.
        //console.log('HTML Clicked ', clickEventData);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (clickEventData.target && clickEventData.target === 'quiz_submit_OK') {
            this.sendRoomCommand(SFS_NAV.CMD_NEXT_SCREEN, new SFSObject());
        } else if (clickEventData.target && clickEventData.target === 'link') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore

            if (clickEventData.value.startsWith('quiz')) {
                const obj: SFSObject = new SFSObject();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                obj.putUtfString(SFSVAR.SFSGAME_MID_LOAD, clickEventData.href.split(':', 2)[1]);
                this.sendRoomCommand(SFS_NAV.CMD_ANSWER, obj);
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            else if (clickEventData.value.startsWith('glossarylink::')) {
                // console.log("Event enter : ", clickEventData);
                /* const m: AuxiliaryMessage = {
                    text: 'Description of the current term',
                    title: clickEventData.value?.replaceAll('glossarylink::',''),
                    titleColor: undefined,
                    buttonOk: undefined,
                    buttonCancel: undefined,
                    buttonAuxiliary: undefined,
                };*/
                if (clickEventData.value?.replaceAll('glossarylink::', '')) {
                    this.props.table_setTooltip({
                        name: clickEventData.value?.replaceAll('glossarylink::', '').toLowerCase(),
                        boundingClientRect: clickEventData.boundingClientRect,
                    });
                }
            }
        } else if (clickEventData.target === 'cancel') {
            //console.log("Event exit : ", clickEventData);
            if (clickEventData?.value && clickEventData?.value.startsWith('glossarylink::')) {
                this.props.table_setTooltip(undefined);
            }
        }
        this.props.output_reset();
    };

    protected clientRequestsSettingsUpdate() {
        const sfsObj = new SFSObject();
        sfsObj.putUtfString('speedCards', this.props.app.settings.speedCards);
        sfsObj.putUtfString('speedScreens', this.props.app.settings.speedScreens);
        sfsObj.putBool('requiredNext', this.props.app.settings.requiredNext);
        this.sendRoomCommand(SFS_NAV.CMD_LOAD_SETTINS, sfsObj);
    }

    //Game actions by user sent to server
    protected clientRequestsMakeCall = (bid: Bid) => {
        this.props.output_reset();
        //console.log('bid: ', bid);
        //Disable Bidding Keyboard
        this.props.table_updateBiddingLadder({
            //  isActive: false,
        });
        const sfsObj = new SFSObject();
        //IMPORTANT Call need to have Int parameter, one of  /* Call intValue constants */
        sfsObj.putUtfString(SFSVAR.SFSGAME_MID_LOAD, bid.call);
        sfsObj.putBool(SFSVAR.SFSGAME_MID_ROBOT, false);
        this.sendRoomCommand(SFSVAR.CMD_MAKE_CALL, sfsObj);
    };

    protected clientRequestsMakePlay(card: Card) {
        //    console.log('Make a Play : ', card);
        this.props.output_reset();
        this.props.table_setWalkTrough(undefined);
        this.props.table_setAuxiliaryMessage({ position: 'left', message: undefined });
        this.props.table_setAuxiliaryMessage({ position: 'center', message: undefined });
        // console.log('card : ', card);
        this.props.table_setSeatData({
            seatPosition: bridgePosToSeatPos(card.bridgePosition),
            isInteractive: false,
            background: undefined,
        });
        const sfsObj = new SFSObject();
        sfsObj.putUtfString(SFSVAR.SFSGAME_MID_LOAD, card.id);
        sfsObj.putBool(SFSVAR.SFSGAME_MID_ROBOT, false);
        sfsObj.putInt(SFSVAR.SFSGAME_CURENT_PLAYER, convertBridgePositionToInt(card.bridgePosition));
        this.sendRoomCommand(SFSVAR.CMD_MAKE_PLAY, sfsObj);
    }

    protected clientRequestsClaim = () => {
        console.log('IsShark Zone', this.state.isSharkZone);
        const sfsObj = new SFSObject();
        if (this.state.isSharkZone) {
            sfsObj.putInt(SFSVAR.SFSGAME_CURENT_PLAYER, this.state.currentPlayer ? this.state.currentPlayer : -1);
            sfsObj.putInt(SFSVAR.SFSGAME_MID_LOAD, 10);
        }
        this.sendRoomCommand(SFSVAR.CMD_REQUEST_CLAIM, sfsObj);
    };

    protected clientRequestsAcceptClaim = () => {
        const sfsObj = new SFSObject();
        sfsObj.putBool(SFSVAR.CLAIM_RESPONSE, true);
        this.sendRoomCommand(SFSVAR.CMD_RESPONSE_CLAIM, sfsObj);
    };

    protected clientRequestsCloseTrick() {
        // console.log('Close Trick');
        this.props.output_reset();
        this.sendRoomCommand(SFSVAR.CMD_CLOSE_TRICK, new SFSObject());
    }

    //SFS send helpers
    protected sendRoomCommand(cmd: string, params: SFSObject | undefined) {
        if (!this.sfs.lastJoinedRoom.isGame) {
            return;
        }
        this.sfs.send(new ExtensionRequest(cmd, params, this.sfs.lastJoinedRoom));
    }
}

export const mapStateToProps = ({ app, cards, output, table }: RootState) => ({
    app,
    cards,
    output,
    table,
});

export const mapDispatchToProps = {
    ...appActions,
    ...cardActions,
    ...outputActions,
    ...tableActions,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GameEngineInterface);
