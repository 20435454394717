import {Bid, Card, ClickEventData} from '../app/types';
import { Button } from './tableSlice';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type OutputState = {
    button: Pick<Button, 'id' | 'value'> | undefined;
    bid: Bid | undefined;
    card: Card | undefined;
    clickEventData:
        | ClickEventData
        | undefined;
};

export const initialState: OutputState = {
    button: undefined,
    bid: undefined,
    card: undefined,
    clickEventData: undefined,
};

export const outputSlice = createSlice({
    name: 'output',
    initialState,
    reducers: {
        output_setButton: (state, { payload }: PayloadAction<OutputState['button']>) => {
            state.button = payload;
        },
        output_setBid: (state, { payload }: PayloadAction<Bid>) => {
            state.bid = payload;
        },
        output_setCard: (state, { payload }: PayloadAction<Card>) => {
            state.card = payload;
        },
        output_setWalkThruTarget: (state, { payload }: PayloadAction<OutputState['clickEventData']>) => {
            state.clickEventData = payload;
        },
        // resets
        output_reset: () => initialState,
    },
});

export const outputActions = outputSlice.actions;

// TODO: add some selectors
// export const selectHighlighted = (state: RootState) => state.card.highlighted;
// export const selectRaised = (state: RootState) => state.card.raised;
// export const selectRank = (state: RootState) => state.card.rank;
// export const selectSuit = (state: RootState) => state.card.suit;
// export const selectSuitSymbol = (state: RootState) => state.card.suitSymbol;
// export const selectVisible = (state: RootState) => state.card.visible;

export default outputSlice.reducer;
