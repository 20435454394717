export enum BridgePosition {
    north = 'north',
    east = 'east',
    south = 'south',
    west = 'west',
}

export enum SeatPosition {
    top = 'top',
    right = 'right',
    bottom = 'bottom',
    left = 'left',
}

export enum Vulnerable {
    none = 'none',
    ns = 'ns',
    ew = 'ew',
    both = 'both',
}

export enum Suit {
    clubs = 'clubs',
    hearts = 'hearts',
    spades = 'spades',
    diamonds = 'diamonds',
}

export enum Rank {
    ace = 'ace',
    king = 'king',
    queen = 'queen',
    jack = 'jack',
    ten = 'ten',
    nine = 'nine',
    eight = 'eight',
    seven = 'seven',
    six = 'six',
    five = 'five',
    four = 'four',
    three = 'three',
    two = 'two',
}

export const suits: Suit[] = [Suit.spades, Suit.hearts, Suit.diamonds, Suit.clubs];
export const ranks: Rank[] = [
    Rank.ace,
    Rank.king,
    Rank.queen,
    Rank.jack,
    Rank.ten,
    Rank.nine,
    Rank.eight,
    Rank.seven,
    Rank.six,
    Rank.five,
    Rank.four,
    Rank.three,
    Rank.two,
];

export type Bid = {
    call: Call;
    backgroundColor: string | undefined;
    bridgePosition?: BridgePosition;
    disabled?: boolean;
    invisible?: boolean;
    key?: number;
};

export type Auction = Bid[];

export type Call =
    | '1c'
    | '1d'
    | '1h'
    | '1nt'
    | '1s'
    | '2c'
    | '2d'
    | '2h'
    | '2nt'
    | '2s'
    | '3c'
    | '3d'
    | '3h'
    | '3nt'
    | '3s'
    | '4c'
    | '4d'
    | '4h'
    | '4nt'
    | '4s'
    | '5c'
    | '5d'
    | '5h'
    | '5nt'
    | '5s'
    | '6c'
    | '6d'
    | '6h'
    | '6nt'
    | '6s'
    | '7c'
    | '7d'
    | '7h'
    | '7nt'
    | '7s'
    | 'pass'
    | 'q'
    | 'rdbl'
    | 'xdbl';

export enum CardPosition {
    DECK = 'DECK',
    HAND = 'HAND',
    TRICK = 'TRICK',
    CLAIMED_TRICK = 'CLAIMED_TRICK',
}

export type CardId =
    | 'c2'
    | 'c3'
    | 'c4'
    | 'c5'
    | 'c6'
    | 'c7'
    | 'c8'
    | 'c9'
    | 'ca'
    | 'cj'
    | 'ck'
    | 'cq'
    | 'ct'
    | 'd2'
    | 'd3'
    | 'd4'
    | 'd5'
    | 'd6'
    | 'd7'
    | 'd8'
    | 'd9'
    | 'da'
    | 'dj'
    | 'dk'
    | 'dq'
    | 'dt'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'h7'
    | 'h8'
    | 'h9'
    | 'ha'
    | 'hj'
    | 'hk'
    | 'hq'
    | 'ht'
    | 's2'
    | 's3'
    | 's4'
    | 's5'
    | 's6'
    | 's7'
    | 's8'
    | 's9'
    | 'sa'
    | 'sj'
    | 'sk'
    | 'sq'
    | 'st';

export type Card = {
    bridgePosition: BridgePosition | undefined;
    cardPosition: CardPosition;
    id: CardId;
    highlighted: boolean;
    raised: boolean;
    rank: Rank;
    suit: Suit;
    suitSymbol: boolean | undefined;
    visible: boolean | undefined;
};

export type Cards = Record<Card['id'], Card>;

export type SeatData = {
    background: string | undefined;
    bridgePosition: BridgePosition | undefined;
    cardWidthMultiplier: number;
    dummyCards: number;
    dynamicCardColumnsWidth: boolean;
    isInteractive: boolean;
    isVertical: boolean;
    isVisible: boolean;
    labelPrimary: string | undefined;
    labelSecondary: string | undefined;
    labelTertiary: string | undefined;
    size: 's' | 'm' | 'l' | 'd';
};

export enum SuitOrder {
    CDSH = 'CDSH',
    CHSD = 'CHSD',
    DCHS = 'DCHS',
    DSHC = 'DSHC',
    HCDS = 'HCDS',
    HSDC = 'HSDC',
    SDCH = 'SDCH',
    SHCD = 'SHCD',
    ENGINE = 'ENGINE',
}

export enum GamePhase {
    PRE = 'PRE',
    DEAL = 'DEAL',
    BID = 'BID',
    BID_PLAY = 'BID_PLAY',
    PLAY = 'PLAY',
    END = 'END',
}

export type HandLayout = 'straight' | 'stacked';

export enum BonusIndicator {
    'j' = 'major game', // 3nt, 4h, 4s
    'm' = 'minor game', // 5c, 5d
    's' = 'slam', // 6xx
    'g' = 'GrandSlam', // 7xx
}

export type WalkThrough = {
    content: string;
};

export type BiddingLadder = {
    bids: Bid[];
    bonusIndicators: BonusIndicator[];
    firstVisibleCall: Call;
    isActive: boolean;
    isVisible: boolean;
    minimalValidCall: Call;
    showArrow: boolean;
    showBonusIndicator: boolean;
    stake: Pick<Bid, 'disabled' | 'backgroundColor' | 'invisible'> & { call: Extract<Call, 'rdbl' | 'xdbl'> };
    pass: Pick<Bid, 'disabled' | 'backgroundColor' | 'invisible'>;
};

export type Trick = {
    cardIds: CardId[];
    winner?: BridgePosition | undefined;
};

export enum Speed {
    verySlow = 'verySlow',
    slow = 'slow',
    medium = 'medium',
    fast = 'fast',
    veryFast = 'veryFast',
    instant = 'instant',
}

export type DOMRectObj = {
    x: number;
    y: number;
    width: number;
    height: number;
    top: number;
    right: number;
    bottom: number;
    left: number;
};

export type ClickEventData = {
    value: string | undefined;
    target: string | undefined;
    boundingClientRect: DOMRectObj;
};

export type GlossaryEntry = {
    name: string;
    title: string;
    description: string;
};
